//英语
export default{
    title: 'Software download',
    label_title: 'Download the projection software client',
    label_subtitle: 'Also supports Windows, macOS, iOS, iPadOS, Android, Linux versions',
    label_subtitle_korea: 'Supports Windows, Android, and Linux versions simultaneously',
    label_reduce_subtitle: 'Supports both Windows and Android versions',
    label_ok: 'OK',
    label_cancel: 'Cancel',
    label_video: 'Use the help video',
    label_video_subtitle: 'Easy to teach you how to use the device: video explanations on the use of functions such as wallpaper setting, screen projection code use, etc.',
    label_video_button: 'Watch more videos',
    label_footer:'Copyright@2023 Wireless Projection System All rights reserved ',
    label_footer1:'Guangdong ICP No. 14037583',
    label_dowload:'Click to download',
    label_new_dowload:'New version download',
    label_old_dowload:'Old version download',

    label_windows_title:'Remote Desktop Software for Windows',
    label_windows_subtitle:'Remotely control Windows, macOS, Android, iOS, Linux and other devices through Windows',
    label_windows_subtitle1:'Compatible with Windows 7 and above',


    label_macos_title:'Remote Desktop Software for Mac',
    label_macos_subtitle:'Remotely control Windows, macOS, Android, iOS, Linux and other devices through Mac',
    label_macos_subtitle1:'Compatible with OSX 10.10 and above',


    label_ipados_title:'Remote Desktop Software for iOS&iPadOS',
    label_ipados_subtitle:'Remotely control Android, iOS, iPadOS, Linux and other devices through iOS&iPadOS to easily achieve mobile office',
    label_ipados_subtitle1:'Compatible with iOS 9.0 and above',

    label_android_title:'Remote Desktop Software for Android',
    label_android_subtitle:'Remotely control Android, Linux and other devices through Android to easily achieve mobile office',
    label_android_subtitle1:'Compatible with Android 5.1 and above',

    label_linux_title:'Remote Desktop Software for Linux',
    label_linux_subtitle:'Support Ubuntu, Deepin, CentOS, Xinchuang UOS, Kirin, Zhongke Fangde and other systems',
    label_linux_subtitle1:'Compatible with Ubuntu 64-bit 16.0 and above',



    label_privacy_title:'Privacy statement',
    label_privacy_policy_title:'Privacy Policy',
    label_privacy_policy_date:'Effective date: October 17, 2019',
    label_privacy_policy_body1:'Shenzhen Bozee Technology Co., Ltd ("We") operates http://www.quicksharevip.com/privacy related websites and quichkshare mobile applications ("Services").',
    label_privacy_policy_body2:'This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Services and the choices you have related to that data.',
    label_privacy_policy_body3:'We use your data to provide and improve the Service. By using the Service, you consent to the collection and use of information in accordance with this Policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as those used in our Terms and Conditions.',

    label_privacy_definition:'Definition',
    label_privacy_definition_personal:'Personal data',
    label_privacy_definition_personal_data:'Personal data means data from which an individual can be identified (or from other information in our possession or that we may have).',
    label_privacy_definition_usage:'Usage data',
    label_privacy_definition_usage_data:'Usage Data is data collected automatically through the use of the Services or data generated from the Services infrastructure itself (for example, the duration of a page visit).',
    label_privacy_definition_cookies:'Cookies',
    label_privacy_definition_cookies_data:'Cookies are small pieces of data stored on a user\'s device.',
    label_privacy_definition_controller:'Data controller',
    label_privacy_definition_controller_data:'Data controller is the person who (either alone or jointly or jointly with others) determines the present or future use and processing of personal data.',
    label_privacy_definition_processor:'Data Processor (or Service Provider)',
    label_privacy_definition_processor_data1:'Data Processor (or Service Provider) means any person (other than an employee of the Data Controller) who processes data on behalf of the Data Controller.',
    label_privacy_definition_processor_data2:'In order to process your data more efficiently, we may use the services of various service providers.',
    label_privacy_definition_subject:'Data subject',
    label_privacy_definition_subject_data:'Data subject means any person who is the subject of personal data.',
    label_privacy_definition_user:'User',
    label_privacy_definition_user_data:'Users are individuals who use our Services. Users correspond to data subjects, that is, subjects of personal data.',


    label_privacy_collect:'How do we use this information?',
    label_privacy_collect_personal:'Personal data',
    label_privacy_collect_personal_data:'When you use our Services, we may ask you to provide certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:',
    label_privacy_collect_personal_data_mail:'Email address',
    label_privacy_collect_personal_data_name:'Name',
    label_privacy_collect_personal_data_tel:'Telephone number',
    label_privacy_collect_personal_data_address:'Address, country, province, zip code, city',
    label_privacy_collect_personal_data_cookiesAusage:'Cookies and Usage Data',
    label_privacy_collect_personal_data1:'We may use your personal data to send you newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt-out of receiving any or all of these communications from us by following the unsubscribe link or instructions provided in any email we send.',
    label_privacy_collect_usage:'Usage data',
    label_privacy_collect_usage_data1:'We may also collect information that your browser sends when you visit our Services or when you access our Services from a mobile device ("Usage Data").',
    label_privacy_collect_usage_data2:'This Usage Data includes your computer\'s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of the Service that you visit, favorites, videos watched, favorite videos, video playlists, the time of your visit and information such as the date, time spent on these pages, unique device identifiers and other diagnostic data.',
    label_privacy_collect_usage_data3:'When you access the Service via a mobile device, this usage data includes the type of mobile device you use, your mobile device\'s unique ID, your mobile device\'s IP address, your mobile operating system, and the mobile Internet browsing method you use. information such as the type of device, unique device identifier, and other diagnostic data.',
    label_privacy_collect_device:'Device management',
    label_privacy_collect_device_data:'We need to collect your device model and unique device identifier (Android ID for Android and UUID for iOS) to manage each terminal device and perform login authentication when using a new device.',
    label_privacy_collect_security:'Security',
    label_privacy_collect_security_data:'In order to ensure the security of your account and transactions, we will collect and process your immutable device identification (hardware serial number), variable device identification (Android ID, IP address, OAID), basic device information ( Device model, device manufacturer, device serial number, mobile phone brand), basic hardware information (IMSI, IMEI, MAC address), operating system and software version information, device status, network status, floating window, IDFA, UUID, and will be used If your permission is stored, we will also use the aforementioned information to prevent various illegal uses or infringement of legitimate rights and interests.',
    label_privacy_collect_location:'Location data',
    label_privacy_collect_location_data1:'With your permission, we may use and store your location information ("Location Data"). We use this data to provide you with our featured services and to improve and customize our services to you.',
    label_privacy_collect_location_data2:'When you use our Services at any time, you can enable or disable location services through your device settings.',
    label_privacy_collect_internet:'Internet Information',
    label_privacy_collect_internet_data:'We need to obtain the connected Wi-Fi name (SSID) of the device during use to ensure the normal operation of the network connection service.',
    label_privacy_collect_devicelist:'Device List',
    label_privacy_collect_devicelist_data:'After obtaining user authorization, the application will automatically run the device search function, and we will collect your device list information to complete the device connection service.',
    label_privacy_collect_file:'Files/Pictures/Video/Audio',
    label_privacy_collect_file_data:'After obtaining user authorization, when actively entering multimedia screencasting, we will collect your files/pictures/videos/audio as part of the screencast content to provide you with multimedia screencasting services.',
    label_privacy_collect_cookies:'Tracking Cookies Data',
    label_privacy_collect_cookies_data1:'We use cookies and similar tracking technologies to track activity on our Service and store certain information.',
    label_privacy_collect_cookies_data2:'Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also use beacons, tags, and scripts to collect and track information and to improve and analyze our Services.',
    label_privacy_collect_cookies_data3:'You can instruct your browser to refuse all cookies, or to indicate when cookies are sent. However, if you do not accept Cookies, you may not be able to use some of our services. Examples of Cookies we use:',
    label_privacy_collect_cookies_data4:'Session Cookies: We use session cookies to operate our services.',
    label_privacy_collect_cookies_data5:'Preference Cookies: We use preference cookies to remember your preferences and various settings.',
    label_privacy_collect_cookies_data6:'Security Cookies: We use security cookies for security.',
    label_privacy_collect_cookies_data7:'Advertising Cookies: We use advertising cookies to provide you with advertising that may be relevant to you and your interests.',
    label_privacy_collect_sensor:'Sensor application scenarios',
    label_privacy_collect_sensor_data:'n the following application scenarios, we may call your sensor permissions to provide you with corresponding business functions through your sensor information (separate sensor information, not personal information). Please be aware that device sensor information alone does not involve any personal location information and cannot be combined with other information to identify a specific natural person.',
    label_privacy_collect_sensor_data1:'Acceleration sensor, direction sensor: Switch between horizontal and vertical screens during picture display, detect screen orientation changes, and switch between horizontal and vertical screens for picture display;',
    label_privacy_collect_sensor_data2:'Acceleration sensor: Wireless remote control function, identifying the device status to determine the user\'s specific operation;',
    label_privacy_collect_sensor_data3:'Light sensor: Scan code function to detect light to adjust screen brightness;',
    label_privacy_collect_sensor_data4:'Rotation vector sensor, gyroscope sensor, acceleration sensor: Remote mouse simulation, identifying the direction and displacement of the simulated mouse;',
    label_privacy_collect_sensor_data5:'Acceleration sensor, magnetic field sensor, direction sensor, pressure sensor, gravity sensor, rotation vector sensor: In-application positioning service and other general functions can assist in obtaining location information after user authorization;',
    label_privacy_collect_other:'Other permissions',
    label_privacy_collect_other_data:'When you use the services corresponding to our products, you may need to call or use your device functions to implement the corresponding business functions. Before you use the service, you can choose whether to authorize the following permissions:',
    label_privacy_collect_other_data1:'Camera (camera)-based functions: You can turn on the camera to perform operations such as mirroring the camera screen, taking photos, and scanning QR codes;',
    label_privacy_collect_other_data2:'Album-based functions: When you cast pictures to the screen, by turning on the allow access to the album function, you can select pictures from the local album for screencasting;',
    label_privacy_collect_other_data3:'Microphone (recording)-based functions: You can select a microphone device for voice transmission in specific scenarios;',
    label_privacy_collect_other_data4:'Geolocation-based functions: You can turn on location services to facilitate applications to detect Wi-Fi status and other functions;',
    label_privacy_collect_other_data5:'Based on the function of reading the mobile phone\'s storage space: You can enable the permission to read the mobile phone\'s storage space and complete operations such as delivering local photo albums, documents, audio files, etc.;',
    label_privacy_collect_other_data6:'Function based on writing to external storage: You can enable the permission to write to external storage to complete operations such as saving wireless snapshots;',
    label_privacy_collect_other_data7:'Functions based on external file management permissions: In some higher version systems, you need to enable external file management permissions to deliver documents or deliver specified albums;',

    label_privacy_how:'How do we use this information?',
    label_privacy_bozee:'Shenzhen Bozee Technology Co., Ltd uses the collected data for:',
    label_privacy_bozee_data1:'To provide and maintain our services',
    label_privacy_bozee_data2:'To notify you about changes to our services',
    label_privacy_bozee_data3:'Allow you to participate in interactive features of our Services, but only if you choose to do so',
    label_privacy_bozee_data4:'Provide customer support',
    label_privacy_bozee_data5:'Collect analytical data or important information to improve our services',
    label_privacy_bozee_data6:'Monitor usage of our services',
    label_privacy_bozee_data7:'Detect, prevent and resolve technical issues',
    label_privacy_bozee_data8:'Provide you with news, special offers and general information about other similar goods, services and events we offer based on the goods, services and events you have purchased or inquired about, unless you have chosen not to receive such information',

    label_privacy_reserve:'How long is this information retained?',
    label_privacy_reserve_data1:'Shenzhen Bozee Technology Co., Ltd will only retain your personal data for the time required for the purposes specified in this Privacy Policy. We retain and use your personal data solely to comply with our legal obligations (for example, we retain your data to comply with applicable law), resolve disputes, and enforce our legal agreements and policies.',
    label_privacy_reserve_data2:'Shenzhen Bozee Technology Co., Ltd will also retain usage data for internal analysis. Usage data is generally retained for a shorter period of time unless the data is used to enhance security or improve the functionality of our services or we are legally obligated to retain the data for a longer period of time.',

    label_privacy_shared:'How is this information shared?',
    label_privacy_shared_data1:'Your information, including Personal Data, may be transferred to and stored on computers outside your state, province, country or other governmental jurisdiction where the data protection laws may be different than the data protection laws from your jurisdiction. different. If you are outside of China and choose to provide information to us, please note that we will transfer the data, including Personal Data, to China and process it in China.',
    label_privacy_shared_data2:'Your consent to this Privacy Policy by submitting this information constitutes your consent to that transfer.',
    label_privacy_shared_data3:'Shenzhen Bozee Technology Co., Ltd will take all necessary measures to ensure that your data is processed securely and in accordance with this Privacy Policy, unless appropriate controls are in place (including the protection of your data and other personal information). Except for security), your personal data will not be transferred to any organization or country.',


    label_privacy_law:'How do we respond to legal requests?',
    label_privacy_law_disclosure:'isclosed in accordance with the law',
    label_privacy_law_disclosure_data:'Under certain circumstances, Shenzhen Bozee Technology Co., Ltd. may be required to disclose your personal data in accordance with the law or in response to valid requests from public authorities (such as courts or government agencies).',
    label_privacy_law_require:'Legal requirements',
    label_privacy_law_require_data:'Shenzhen Bozee Technology Co., Ltd may disclose your personal data as long as it has reasonable grounds to believe that it is necessary for the following situations:',
    label_privacy_law_require_data1:'Comply with legal obligations',
    label_privacy_law_require_data2:'Protect and defend the rights or property of Shenzhen Bozee Technology Co., Ltd.',
    label_privacy_law_require_data3:'Prevent or investigate possible misconduct in connection with the Services',
    label_privacy_law_require_data4:'Protect the personal safety of service users and the public',
    label_privacy_law_require_data5:'immunity from legal liability',


    label_privacy_safety:'How secure is your data?',
    label_privacy_safety_data:'The security of your data is important to us, but please note that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially feasible means to protect your personal data, we cannot guarantee its absolute security.',


    label_privacy_delete_data:'How can you manage or delete information about you?',
    label_privacy_delete_data1:'Shenzhen Bozee Technology Co., Ltd will take all reasonable measures to allow you to correct, modify, delete your personal data or restrict its use.',
    label_privacy_delete_data2:'If you are unable to change your personal data, please contact us so that the required changes can be made.',
    label_privacy_delete_data3:'If you would like to know what personal data we hold about you and you would like us to delete it from our systems, please contact us.',
    label_privacy_delete_data4:'Under certain circumstances, you have the right to:',
    label_privacy_delete_data5:'Correct any incorrect personal data about you',
    label_privacy_delete_data6:'Request deletion of your personal data',
    label_privacy_delete_data7:'You have the right to transfer the information you provide to Shenzhen Bozee Technology Co., Ltd. You can request a copy of your personal data in a commonly used electronic format to facilitate your management and portability of this data. Please note that we may ask you to prove your identity before responding to such a request.',



    label_privacy_cooperate:'How we share information with third party partners',
    label_privacy_cooperate_body:'We may employ third party companies and individuals ("Service Providers") to facilitate our Service, to provide the Service on our behalf, to perform services related to our Service or to assist us in analyzing how our Service is used. These third parties have access to your Personal Data in order to perform these tasks on our behalf and are obligated not to disclose or make other uses of the data.',
    label_privacy_cooperate_analyze:'Analyze',
    label_privacy_cooperate_analyze_data:'We may use third-party service providers to monitor and analyze the use of our Services.',
    label_privacy_cooperate_analyze_data1:'Google Analytics',
    label_privacy_cooperate_analyze_data2:'Google Analytics is a web analytics service provided by Google that tracks and reports website traffic. Google uses the collected data to track and monitor the use of our services. This data is shared with other Google services. Google may use the data collected to contextualize and personalize the ads of its own advertising network.',
    label_privacy_cooperate_analyze_data3:'You may opt out of having your activity on the Service provided to Google Analytics by installing the Google Analytics opt-out browser add-on in your regular browser environment or by changing settings within our Applications. This add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information about visit activity with Google Analytics.',
    label_privacy_cooperate_analyze_data4:'For more information about Google’s privacy practices, please visit the Google Privacy Terms webpage: http://www.google.com/intl/en/policies/privacy/',
    label_privacy_cooperate_firebase:'Firebase',
    label_privacy_cooperate_firebase_data:'Firebase is a web analytics service provided by Google for tracking and reporting application usage. Google uses the collected data to track and monitor the use of our services. This data is shared with other Google services. Google may use the data collected to contextualize and personalize the ads of its own advertising network.',
    label_privacy_cooperate_firebase_data1:'You can choose to stop having your activity on the Service provided to Google Analytics by changing the settings within our Application.',
    label_privacy_cooperate_firebase_data2:'For more information about Google’s privacy practices, please visit the Google Privacy Terms webpage: http://www.google.com/intl/en/policies/privacy/',
    label_privacy_cooperate_advertise:'Advertise',
    label_privacy_cooperate_advertise_data:'We may use third-party service providers to deliver advertisements to you and to help support and maintain our Services.',
    label_privacy_cooperate_advertise_data1:'Google AdSense DoubleClick Cookie',
    label_privacy_cooperate_advertise_data2:'Google, as a third-party vendor, uses cookies to serve ads on our services. Google\'s use of the DoubleClick cookie enables it and its partners to serve ads to our users based on their visit to our Services or other sites on the Internet.',
    label_privacy_cooperate_advertise_data3:'You can opt out of the use of the DoubleClick cookie for interest-based advertising by visiting the Google Ad Settings webpage: http://www.google.com/ads/preferences/',
    label_privacy_cooperate_advertise_data4:'GoogleAdMob',
    label_privacy_cooperate_advertise_data5:'Google AdMob is provided by Google Inc.',
    label_privacy_cooperate_advertise_data6:'You can choose to opt out of Google AdMob service. Please see Google\'s instructions for specific operations: https://support.google.com/ads/answer/2662922?hl=en',
    label_privacy_cooperate_advertise_data7:'For more information about how Google uses the information collected, please visit the "How Google uses data when you use our partners\' sites or apps" page: http://www.google.com/policies/privacy/partners / Or visit Google’s privacy policy: http://www.google.com/policies/privacy/',
    label_privacy_cooperate_behavior:'Behavioral remarketing',
    label_privacy_cooperate_behavior_data:'After you visit our services, Shenzhen Bozee Technology Co., Ltd uses remarketing services to push advertisements to you on third-party websites. We and our third-party vendors use cookies to inform, optimize and serve ads based on data from your past visits to our Services.',
    label_privacy_cooperate_behavior_data1:'Google AdWords',
    label_privacy_cooperate_behavior_data2:'Google AdWords remarketing service is provided by Google Inc. You can opt out of Google Analytics Display Advertising and customize Google Display Network ads by visiting the Google Ad Settings page: http://www.google.com/settings/ads',
    label_privacy_cooperate_behavior_data3:'Google also recommends installing the Google Analytics Opt-out Browser Add-on for your web browser - https://tools.google.com/dlpage/gaoptout. The Google Analytics Opt-out Browser Add-on allows visitors to prevent the collection and use of their data by Google Analytics.',
    label_privacy_cooperate_behavior_data4:'For more information about Google’s privacy practices, please visit the Google Privacy Terms webpage: http://www.google.com/intl/en/policies/privacy/',


    label_privacy_cooperate_payment:'Payment',
    label_privacy_cooperate_payment_data:'We may provide paid products and/or services within the scope of the Services. For this case, we use third-party payment processing services (such as payment processors).',
    label_privacy_cooperate_payment_data1:'We do not store or collect your payment card information. This information is provided directly to our third-party payment processor, who will use your personal information in accordance with their privacy policies. These payment processors follow the standards set by PCI DSS under the PCI Security Standards Council, a council formed by brands such as Visa, Mastercard, American Express and Discover. PCI DSS requirements help ensure secure processing of payment information.',
    label_privacy_cooperate_payment_data2:'The payment processor we work with is: PayPal',
    label_privacy_cooperate_payment_data3:'Their privacy policy can be found at https://www.paypal.com/webapps/mpp/ua/privacy-full',

    label_privacy_cooperate_link:'Links to other websites',
    label_privacy_cooperate_link_data:'Our Service may contain links to other websites that are not operated by us. If you click on a third-party link, you will enter the third-party website. We strongly recommend that you review the privacy policy of each website you visit. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third party sites or services.',

    label_privacy_cooperate_child:'Children\'s privacy',
    label_privacy_cooperate_child_data:'We do not provide services to children under the age of 13 ("Children"). We do not knowingly collect personally identifiable information from children under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with Personal Data, please contact us. If we discover that we have collected a child\'s personal data without parental consent, we will take steps to delete this information from our servers.',

    label_privacy_cooperate_policy:'How do we notify you about changes to this policy?',
    label_privacy_cooperate_policy_data:'We may update our privacy policy from time to time. We will notify you of any changes to our privacy policy by posting the new privacy policy on this page. We will notify you of such changes via email and/or prominently post a notice on our Service before the changes become effective, and update the "Effective Date" at the top of the Privacy Policy. You are advised to check this Privacy Policy regularly to see if there have been any changes. Any changes to this Privacy Policy are effective when posted on this page.',

    label_privacy_cooperate_connect:'How to contact us?',
    label_privacy_cooperate_connect_data:'If you have any questions about this Privacy Policy, please contact us:',
    label_privacy_cooperate_connect_data1:'Email: vs.l@bozee.cn',
    label_privacy_cooperate_connect_data2:'Visit this page of our website: http://www.quicksharevip.com/privacy',
}