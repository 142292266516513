import Vue from 'vue'
import VueRouter from 'vue-router'
import { isMobile } from "../utils";
import { mobileRoutes } from "./mobile";
import { pcRoutes } from "./pc";
Vue.use(VueRouter)

const router = new VueRouter({
  mode:"history",
  routes: isMobile() ? mobileRoutes : pcRoutes
})


export default router
