//俄语
export default{
    title: 'загрузка программного обеспечения',
    label_title: 'Загрузите клиент программного обеспечения для зеркалирования экрана',
    label_subtitle: 'Также поддерживает версии для Windows, macOS, iOS, iPadOS, Android и Linux.',
    label_subtitle_korea: 'Поддерживает версии для Windows, Android и Linux одновременно.',
    label_reduce_subtitle: 'Поддерживает версии для Windows и Android.',
    label_ok: 'Конечно',
    label_cancel: 'Отмена',
    label_video: 'Использование справочных видеороликов',
    label_video_subtitle: 'Легко научит пользоваться устройством: видеообъяснения о том, как устанавливать обои, использовать коды скринкастов и другие функции.',
    label_video_button: 'Посмотреть больше видео',
    label_footer:'Copyright@2023 Беспроводная проекционная система. Все права защищены. ',
    label_footer1:'Гуандун ICP № 14037583',
    label_dowload:'нажмите, чтобы скачать',
    label_new_dowload:'Скачать новую версию',
    label_old_dowload:'Скачать старую версию',

    label_windows_title:'Программное обеспечение для удаленного рабочего стола для Windows',
    label_windows_subtitle:'Удаленно управляйте Windows, macOS, Android, iOS, Linux и другими устройствами через Windows.',
    label_windows_subtitle1:'Совместимость с Windows 7 и выше',


    label_macos_title:'Программное обеспечение для удаленного рабочего стола для Mac',
    label_macos_subtitle:'Удаленно управляйте Windows, macOS, Android, iOS, Linux и другими устройствами с Mac',
    label_macos_subtitle1:'Совместимость с OSX 10.10 и выше.',

    label_ipados_title:'Программное обеспечение для удаленного рабочего стола для iOS и iPadOS',
    label_ipados_subtitle:'Удаленно управляйте Android, iOS, iPadOS, Linux и другими устройствами через iOS и iPadOS, чтобы легко получить доступ к мобильному офису.',
    label_ipados_subtitle1:'Совместимость с iOS 9.0 и выше.',

    label_android_title:'Программное обеспечение для удаленного рабочего стола для Android',
    label_android_subtitle:'Удаленно управляйте Android, Linux и другими устройствами через Android, чтобы легко получить доступ к мобильному офису.',
    label_android_subtitle1:'Совместимость с Android 5.1 и выше.',

    label_linux_title:'Программное обеспечение для удаленного рабочего стола для Linux',
    label_linux_subtitle:'Поддерживает Ubuntu, Deepin, CentOS, Xinchuang UOS, Kirin, Zhongke Fangde и другие системы.',
    label_linux_subtitle1:'Совместимость с 64-разрядной версией Ubuntu 16.0 и выше.',


    label_privacy_title:'Заявление о конфиденциальности',
    label_privacy_policy_title:'политика конфиденциальности',
    label_privacy_policy_date:'Дата вступления в силу: 17 октября 2019 г.',
    label_privacy_policy_body1:'Shenzhen Bozee Technology Co., Ltd («Мы») управляет веб-сайтами http://www.quicksharevip.com/privacy и мобильными приложениями quichkshare («Сервис»).',
    label_privacy_policy_body2:'На этой странице вы узнаете о нашей политике в отношении сбора, использования и раскрытия персональных данных при использовании наших Сервисов, а также о вариантах выбора, которые вы имеете в отношении этих данных.',
    label_privacy_policy_body3:'Мы используем ваши данные для предоставления и улучшения Сервиса. Используя Сервис, вы даете согласие на сбор и использование информации в соответствии с настоящей Политикой. Если иное не указано в настоящей Политике конфиденциальности, термины, используемые в настоящей Политике конфиденциальности, имеют те же значения, что и термины, используемые в наших Условиях и положениях.',

    label_privacy_definition:'определение',
    label_privacy_definition_personal:'личные данные',
    label_privacy_definition_personal_data:'Персональные данные означают данные, по которым можно идентифицировать человека (или на основе другой информации, которой мы располагаем или которая может быть у нас).',
    label_privacy_definition_usage:'данные об использовании',
    label_privacy_definition_usage_data:'Данные об использовании — это данные, собираемые автоматически посредством использования Сервисов, или данные, генерируемые самой инфраструктурой Сервисов (например, продолжительность посещения страницы).',
    label_privacy_definition_cookies:'Печенье',
    label_privacy_definition_cookies_data:'Файлы cookie — это небольшие фрагменты данных, хранящиеся на устройстве пользователя.',
    label_privacy_definition_controller:'контроллер данных',
    label_privacy_definition_controller_data:'Контроллер данных — это лицо, которое (самостоятельно или совместно или совместно с другими) определяет настоящее или будущее использование и обработку персональных данных.',
    label_privacy_definition_processor:'Обработчик данных (или поставщик услуг)',
    label_privacy_definition_processor_data1:'Обработчик данных (или Поставщик услуг) означает любое лицо (кроме сотрудника Контроллера данных), которое обрабатывает данные от имени Контроллера данных.',
    label_privacy_definition_processor_data2:'Для более эффективной обработки ваших данных мы можем пользоваться услугами различных поставщиков услуг.',
    label_privacy_definition_subject:'субъект данных',
    label_privacy_definition_subject_data:'Субъект данных – любое лицо, являющееся субъектом персональных данных.',
    label_privacy_definition_user:'пользователь',
    label_privacy_definition_user_data:'Пользователи — это физические лица, использующие наши Сервисы. Пользователи соответствуют субъектам данных, то есть субъектам персональных данных.',


    label_privacy_collect:'Как мы используем эту информацию?',
    label_privacy_collect_personal:'личные данные',
    label_privacy_collect_personal_data:'Когда вы пользуетесь нашими Сервисами, мы можем попросить вас предоставить определенную личную информацию, которая может быть использована для связи или идентификации вас («Личные данные»). Личная информация может включать, помимо прочего:',
    label_privacy_collect_personal_data_mail:'Адрес электронной почты',
    label_privacy_collect_personal_data_name:'Имя',
    label_privacy_collect_personal_data_tel:'номер телефона',
    label_privacy_collect_personal_data_address:'Адрес, страна, провинция, почтовый индекс, город',
    label_privacy_collect_personal_data_cookiesAusage:'Файлы cookie и данные об использовании',
    label_privacy_collect_personal_data1:'Мы можем использовать ваши персональные данные для отправки вам информационных бюллетеней, маркетинговых или рекламных материалов и другой информации, которая может вас заинтересовать. Вы можете отказаться от получения каких-либо или всех этих сообщений от нас, перейдя по ссылке для отказа от подписки или инструкциям, содержащимся в любом отправляемом нами электронном письме.',
    label_privacy_collect_usage:'данные об использовании',
    label_privacy_collect_usage_data1:'Мы также можем собирать информацию, которую ваш браузер отправляет, когда вы посещаете наши Сервисы или когда вы получаете доступ к нашим Сервисам с мобильного устройства («Данные об использовании»).',
    label_privacy_collect_usage_data2:'Эти данные об использовании включают в себя адрес интернет-протокола вашего компьютера (например, IP-адрес), тип браузера, версию браузера, страницы Сервиса, которые вы посещаете, избранное, просмотренные видео, избранные видео, списки воспроизведения видео, время вашего посещения и такую ​​​​информацию, как дата, время, проведенное на этих страницах, уникальные идентификаторы устройств и другие диагностические данные.',
    label_privacy_collect_usage_data3:'Когда вы получаете доступ к Сервису через мобильное устройство, эти данные об использовании включают тип используемого вами мобильного устройства, уникальный идентификатор вашего мобильного устройства, IP-адрес вашего мобильного устройства, вашу мобильную операционную систему и используемый вами метод просмотра мобильного Интернета. как тип устройства, уникальный идентификатор устройства и другие диагностические данные.',
    label_privacy_collect_device:'Управление устройствами',
    label_privacy_collect_device_data:'Нам необходимо собрать модель вашего устройства и уникальный идентификатор устройства (Android ID для Android и UUID для iOS), чтобы управлять каждым терминальным устройством и выполнять аутентификацию при входе в систему при использовании нового устройства.',
    label_privacy_collect_security:'Безопасность',
    label_privacy_collect_security_data:'Чтобы обеспечить безопасность вашей учетной записи и транзакций, мы будем собирать и обрабатывать вашу неизменяемую идентификацию устройства (серийный номер оборудования), переменную идентификацию устройства (Android ID, IP-адрес, OAID), базовую информацию об устройстве (модель устройства, производитель устройства, серийный номер устройства, марка мобильного телефона), основная информация об оборудовании (IMSI, IMEI, MAC-адрес), информация о версии операционной системы и программного обеспечения, состояние устройства, состояние сети, плавающее окно, IDFA, UUID и будут использоваться, если ваше разрешение будет сохранено. , мы также будем использовать вышеупомянутую информацию для предотвращения различных незаконных видов использования или нарушения законных прав и интересов.',
    label_privacy_collect_location:'данные о местоположении',
    label_privacy_collect_location_data1:'С вашего разрешения мы можем использовать и хранить информацию о вашем местоположении («Данные о местоположении»). Мы используем эти данные, чтобы предоставлять вам наши рекомендуемые услуги, а также улучшать и адаптировать наши услуги для вас.',
    label_privacy_collect_location_data2:'Когда вы используете наши Сервисы в любое время, вы можете включить или отключить службы определения местоположения через настройки вашего устройства.',
    label_privacy_collect_internet:'Интернет-информация',
    label_privacy_collect_internet_data:'Нам необходимо получить подключенное Wi-Fi-имя (SSID) устройства во время использования, чтобы обеспечить нормальную работу службы сетевого подключения.',
    label_privacy_collect_devicelist:'Список устройств',
    label_privacy_collect_devicelist_data:'После получения авторизации пользователя приложение автоматически запустит функцию поиска устройства, и мы соберем информацию о вашем списке устройств для завершения услуги подключения устройства.',
    label_privacy_collect_file:'Файлы/Изображения/Видео/Аудио',
    label_privacy_collect_file_data:'После получения авторизации пользователя при активном входе в мультимедийный скринкастинг мы будем собирать ваши файлы/изображения/видео/аудио как часть контента скринкаста, чтобы предоставить вам услуги мультимедийного скринкастинга.',
    label_privacy_collect_cookies:'Отслеживание данных файлов cookie',
    label_privacy_collect_cookies_data1:'Мы используем файлы cookie и аналогичные технологии отслеживания для отслеживания активности на нашем Сервисе и хранения определенной информации.',
    label_privacy_collect_cookies_data2:'Файлы cookie — это файлы с небольшим объемом данных, которые могут включать анонимный уникальный идентификатор. Файлы cookie отправляются в ваш браузер с веб-сайта и сохраняются на вашем устройстве. Технологии отслеживания также используют маяки, теги и сценарии для сбора и отслеживания информации, а также для улучшения и анализа наших Сервисов.',
    label_privacy_collect_cookies_data3:'Вы можете указать своему браузеру отказаться от всех файлов cookie или указать, когда файлы cookie отправляются. Однако, если вы не принимаете файлы cookie, вы не сможете пользоваться некоторыми нашими услугами. Примеры файлов cookie, которые мы используем:',
    label_privacy_collect_cookies_data4:'Сеансовые файлы cookie: мы используем сеансовые файлы cookie для работы наших сервисов.',
    label_privacy_collect_cookies_data5:'Файлы cookie предпочтений. Мы используем файлы cookie предпочтений, чтобы запомнить ваши предпочтения и различные настройки.',
    label_privacy_collect_cookies_data6:'Файлы cookie безопасности. Мы используем файлы cookie безопасности в целях безопасности.',
    label_privacy_collect_cookies_data7:'Рекламные файлы cookie. Мы используем рекламные файлы cookie, чтобы предоставлять вам рекламу, которая может иметь отношение к вам и вашим интересам.',
    label_privacy_collect_sensor:'Сценарии применения датчиков',
    label_privacy_collect_sensor_data:'В следующих сценариях применения мы можем вызывать разрешения вашего датчика, чтобы предоставить вам соответствующие бизнес-функции через информацию вашего датчика (отдельную информацию о датчике, а не личную информацию). Имейте в виду, что информация датчиков устройства сама по себе не включает в себя какую-либо информацию о личном местоположении и не может быть объединена с другой информацией для идентификации конкретного физического лица.',
    label_privacy_collect_sensor_data1:'Датчик ускорения, датчик направления: переключение между горизонтальным и вертикальным экранами во время отображения изображения, обнаружение изменений ориентации экрана и переключение между горизонтальным и вертикальным экранами для отображения изображения;',
    label_privacy_collect_sensor_data2:'Датчик ускорения: функция беспроводного дистанционного управления, определяющая состояние устройства для определения конкретных действий пользователя;',
    label_privacy_collect_sensor_data3:'Датчик освещенности: функция сканирования кода для обнаружения света и регулировки яркости экрана;',
    label_privacy_collect_sensor_data4:'Датчик вектора вращения, датчик гироскопа, датчик ускорения: удаленное моделирование мыши, определение направления и смещения моделируемой мыши;',
    label_privacy_collect_sensor_data5:'Датчик ускорения, датчик магнитного поля, датчик направления, датчик давления, датчик силы тяжести, датчик вектора вращения: службы позиционирования в приложении и другие общие функции могут помочь в получении информации о местоположении после авторизации пользователя;',
    label_privacy_collect_other:'Другие разрешения',
    label_privacy_collect_other_data:'Когда вы пользуетесь услугами, соответствующими нашим продуктам, вам может потребоваться вызвать или использовать функции вашего устройства для реализации соответствующих бизнес-функций. Прежде чем использовать службу, вы можете выбрать, разрешать ли следующие разрешения:',
    label_privacy_collect_other_data1:'Функции камеры (камеры): вы можете включить камеру для выполнения таких операций, как зеркальное отображение экрана камеры, съемка фотографий и сканирование QR-кодов;',
    label_privacy_collect_other_data2:'Функции на основе альбомов: при выводе изображений на экран, включив разрешение доступа к функции альбома, вы можете выбирать изображения из локального альбома для вывода на экран;',
    label_privacy_collect_other_data3:'Функции на основе микрофона (записи): вы можете выбрать микрофонное устройство для передачи голоса в конкретных сценариях;',
    label_privacy_collect_other_data4:'Функции на основе геолокации: вы можете включить службы определения местоположения, чтобы приложения могли определять статус Wi-Fi и другие функции;',
    label_privacy_collect_other_data5:'На основе функции чтения памяти мобильного телефона: вы можете включить разрешение на чтение памяти мобильного телефона и выполнять такие операции, как доставка локальных фотоальбомов, документов, аудиофайлов и т. д.;',
    label_privacy_collect_other_data6:'Функция, основанная на записи во внешнее хранилище: вы можете включить разрешение на запись во внешнее хранилище для выполнения таких операций, как сохранение снимков беспроводной сети;',
    label_privacy_collect_other_data7:'Функции, основанные на внешних разрешениях на управление файлами: в некоторых системах более поздних версий вам необходимо включить внешние разрешения на управление файлами для доставки документов или доставки определенных альбомов;',

    label_privacy_how:'Как мы используем эту информацию?',
    label_privacy_bozee:'Shenzhen Bozee Technology Co., Ltd использует собранные данные для:',
    label_privacy_bozee_data1:'Для предоставления и поддержания наших услуг',
    label_privacy_bozee_data2:'Чтобы уведомить вас об изменениях в наших услугах',
    label_privacy_bozee_data3:'Разрешить вам участвовать в интерактивных функциях наших Сервисов, но только если вы этого захотите.',
    label_privacy_bozee_data4:'Обеспечить поддержку клиентов',
    label_privacy_bozee_data5:'Собирайте аналитические данные или важную информацию для улучшения наших услуг.',
    label_privacy_bozee_data6:'Контролируйте использование наших услуг',
    label_privacy_bozee_data7:'Обнаружение, предотвращение и решение технических проблем',
    label_privacy_bozee_data8:'Предоставление вам новостей, специальных предложений и общей информации о других аналогичных товарах, услугах и событиях, которые мы предлагаем, на основе товаров, услуг и событий, которые вы приобрели или о которых спрашивали, если только вы не решили не получать такую ​​информацию.',

    label_privacy_reserve:'Как долго сохраняется эта информация?',
    label_privacy_reserve_data1:'Shenzhen Bozee Technology Co., Ltd будет хранить ваши персональные данные только в течение времени, необходимого для целей, указанных в настоящей Политике конфиденциальности. Мы сохраняем и используем ваши персональные данные исключительно для выполнения наших юридических обязательств (например, мы сохраняем ваши данные для соблюдения действующего законодательства), разрешения споров и обеспечения соблюдения наших юридических соглашений и политик.',
    label_privacy_reserve_data2:'Shenzhen Bozee Technology Co., Ltd также сохранит данные об использовании для внутреннего анализа. Данные об использовании обычно хранятся в течение более короткого периода времени, за исключением случаев, когда данные используются для повышения безопасности или улучшения функциональности наших услуг или мы по закону обязаны хранить данные в течение более длительного периода времени.',

    label_privacy_shared:'Как эта информация передается?',
    label_privacy_shared_data1:'Ваша информация, включая Персональные данные, может передаваться и храниться на компьютерах за пределами вашего штата, провинции, страны или другой государственной юрисдикции, где законы о защите данных могут отличаться от законов о защите данных вашей юрисдикции. Если вы находитесь за пределами Китая и решили предоставить нам информацию, обратите внимание, что мы передадим данные, включая Персональные данные, и обработаем их в Китае.',
    label_privacy_shared_data2:'Ваше согласие с настоящей Политикой конфиденциальности путем предоставления этой информации означает ваше согласие на такую ​​передачу.',
    label_privacy_shared_data3:'Shenzhen Bozee Technology Co., Ltd примет все необходимые меры для обеспечения безопасной обработки ваших данных в соответствии с настоящей Политикой конфиденциальности, если не будут приняты соответствующие меры контроля (включая защиту ваших данных и другой личной информации). ), ваши персональные данные не будут переданы какой-либо организации или стране.',


    label_privacy_law:'Как мы реагируем на юридические запросы?',
    label_privacy_law_disclosure:'Раскрыто в соответствии с законодательством',
    label_privacy_law_disclosure_data:'При определенных обстоятельствах компания Shenzhen Bozee Technology Co., Ltd. может быть обязана раскрыть ваши персональные данные в соответствии с законом или в ответ на действительные запросы государственных органов (таких как суды или государственные учреждения).',
    label_privacy_law_require:'Правовые требования',
    label_privacy_law_require_data:'Shenzhen Bozee Technology Co., Ltd может раскрывать ваши персональные данные, если у нее есть разумные основания полагать, что это необходимо в следующих ситуациях:',
    label_privacy_law_require_data1:'Соблюдать юридические обязательства',
    label_privacy_law_require_data2:'Защищайте и защищайте права и собственность Shenzhen Bozee Technology Co., Ltd.',
    label_privacy_law_require_data3:'Предотвращать или расследовать возможные неправомерные действия в связи с Услугами.',
    label_privacy_law_require_data4:'Обеспечьте личную безопасность пользователей услуг и общественности.',
    label_privacy_law_require_data5:'иммунитет от юридической ответственности',


    label_privacy_safety:'Насколько безопасны ваши данные?',
    label_privacy_safety_data:'Безопасность ваших данных важна для нас, но обратите внимание, что ни один метод передачи через Интернет или метод электронного хранения не является на 100% безопасным. Хотя мы стремимся использовать коммерчески осуществимые средства для защиты ваших личных данных, мы не можем гарантировать их абсолютную безопасность.',


    label_privacy_delete_data:'Как вы можете управлять или удалять информацию о себе?',
    label_privacy_delete_data1:'Shenzhen Bozee Technology Co., Ltd примет все разумные меры, чтобы позволить вам исправлять, изменять, удалять ваши личные данные или ограничивать их использование.',
    label_privacy_delete_data2:'Если вы не можете изменить свои персональные данные, свяжитесь с нами, чтобы можно было внести необходимые изменения.',
    label_privacy_delete_data3:'Если вы хотите узнать, какие персональные данные о вас мы храним, и хотите, чтобы мы удалили их из наших систем, свяжитесь с нами.',
    label_privacy_delete_data4:'При определенных обстоятельствах вы имеете право:',
    label_privacy_delete_data5:'Исправьте любые неверные персональные данные о вас',
    label_privacy_delete_data6:'Запросить удаление ваших личных данных',
    label_privacy_delete_data7:'Вы имеете право передать предоставленную вами информацию компании Shenzhen Bozee Technology Co., Ltd. Вы можете запросить копию своих личных данных в обычно используемом электронном формате, чтобы облегчить управление и переносимость этих данных. Обратите внимание, что мы можем попросить вас подтвердить свою личность, прежде чем ответить на такой запрос.',



    label_privacy_cooperate:'Как мы передаем информацию сторонним партнерам',
    label_privacy_cooperate_body:'Мы можем нанимать сторонние компании и частных лиц («Поставщики услуг») для содействия нашему Сервису, для предоставления Сервиса от нашего имени, для оказания услуг, связанных с нашим Сервисом, или для оказания нам помощи в анализе того, как используется наш Сервис. Эти третьи стороны имеют доступ к вашим Персональным данным для выполнения этих задач от нашего имени и обязаны не раскрывать и не использовать эти данные иным образом.',
    label_privacy_cooperate_analyze:'анализировать',
    label_privacy_cooperate_analyze_data:'Мы можем использовать сторонних поставщиков услуг для мониторинга и анализа использования наших Сервисов.',
    label_privacy_cooperate_analyze_data1:'Гугл Аналитика',
    label_privacy_cooperate_analyze_data2:'Google Analytics — это служба веб-аналитики, предоставляемая Google, которая отслеживает и сообщает о трафике веб-сайта. Google использует собранные данные для отслеживания и контроля использования наших сервисов. Эти данные передаются другим службам Google. Google может использовать собранные данные для контекстуализации и персонализации рекламы своей собственной рекламной сети.',
    label_privacy_cooperate_analyze_data3:'Вы можете отказаться от вашей активности в Сервисе, предоставляемой Google Analytics, установив надстройку браузера для отказа от Google Analytics в вашей обычной браузерной среде или изменив настройки в наших Приложениях. Это дополнение не позволяет JavaScript Google Analytics (ga.js, Analytics.js и dc.js) обмениваться информацией о посещениях с Google Analytics.',
    label_privacy_cooperate_analyze_data4:'Для получения дополнительной информации о политике конфиденциальности Google посетите веб-страницу «Условия конфиденциальности Google»: http://www.google.com/intl/en/policies/privacy/.',
    label_privacy_cooperate_firebase:'Огневая база',
    label_privacy_cooperate_firebase_data:'Firebase — это служба веб-аналитики, предоставляемая Google для отслеживания и составления отчетов об использовании приложений. Google использует собранные данные для отслеживания и контроля использования наших сервисов. Эти данные передаются другим службам Google. Google может использовать собранные данные для контекстуализации и персонализации рекламы своей собственной рекламной сети.',
    label_privacy_cooperate_firebase_data1:'Вы можете прекратить свою активность в Сервисе, предоставляемом Google Analytics, изменив настройки в нашем Приложении.',
    label_privacy_cooperate_firebase_data2:'Для получения дополнительной информации о политике конфиденциальности Google посетите веб-страницу «Условия конфиденциальности Google»: http://www.google.com/intl/en/policies/privacy/.',
    label_privacy_cooperate_advertise:'рекламировать',
    label_privacy_cooperate_advertise_data:'Мы можем использовать сторонних поставщиков услуг для доставки вам рекламы, а также для поддержки и обслуживания наших Сервисов.',
    label_privacy_cooperate_advertise_data1:'Файл cookie DoubleClick Google AdSense',
    label_privacy_cooperate_advertise_data2:'Google, как сторонний поставщик, использует файлы cookie для показа рекламы в наших сервисах. Использование Google файла cookie DoubleClick позволяет ему и его партнерам показывать рекламу нашим пользователям на основе их посещения наших Сервисов или других сайтов в Интернете.',
    label_privacy_cooperate_advertise_data3:'Вы можете отказаться от использования файлов cookie DoubleClick для рекламы на основе интересов, посетив веб-страницу настроек рекламы Google: http://www.google.com/ads/preferences/',
    label_privacy_cooperate_advertise_data4:'GoogleAdMob',
    label_privacy_cooperate_advertise_data5:'Google AdMob предоставляется компанией Google Inc.',
    label_privacy_cooperate_advertise_data6:'Вы можете отказаться от службы Google AdMob. См. инструкции Google для конкретных операций: https://support.google.com/ads/answer/2662922?hl=en.',
    label_privacy_cooperate_advertise_data7:'Для получения дополнительной информации о том, как Google использует собранную информацию, посетите страницу «Как Google использует данные, когда вы используете сайты или приложения наших партнеров»: http://www.google.com/policies/privacy/partners / Или посетите Google политика конфиденциальности: http://www.google.com/policies/privacy/',
    label_privacy_cooperate_behavior:'поведенческий ремаркетинг',
    label_privacy_cooperate_behavior_data:'После того как вы воспользуетесь нашими услугами, компания Shenzhen Bozee Technology Co., Ltd использует услуги ремаркетинга, чтобы показывать вам рекламу на сторонних веб-сайтах. Мы и наши сторонние поставщики используем файлы cookie для информирования, оптимизации и показа рекламы на основе данных о ваших прошлых посещениях наших Сервисов.',
    label_privacy_cooperate_behavior_data1:'Google AdWords',
    label_privacy_cooperate_behavior_data2:'Служба ремаркетинга Google AdWords предоставляется компанией Google Inc. Вы можете отказаться от медийной рекламы Google Analytics и настроить рекламу в контекстно-медийной сети Google, посетив страницу настроек рекламы Google: http://www.google.com/settings/ads.',
    label_privacy_cooperate_behavior_data3:'Google также рекомендует установить надстройку браузера для отключения Google Analytics для вашего веб-браузера — https://tools.google.com/dlpage/gaoptout. Надстройка браузера Google Analytics Opt-out позволяет посетителям запретить сбор и использование их данных Google Analytics.',
    label_privacy_cooperate_behavior_data4:'Для получения дополнительной информации о политике конфиденциальности Google посетите веб-страницу «Условия конфиденциальности Google»: http://www.google.com/intl/en/policies/privacy/.',


    label_privacy_cooperate_payment:'Оплата',
    label_privacy_cooperate_payment_data:'Мы можем предоставлять платные продукты и/или услуги в рамках Услуг. В этом случае мы используем сторонние службы обработки платежей (например, платежные системы).',
    label_privacy_cooperate_payment_data1:'Мы не храним и не собираем информацию о вашей платежной карте. Эта информация предоставляется непосредственно нашему стороннему платежному оператору, который будет использовать вашу личную информацию в соответствии со своей политикой конфиденциальности. Эти платежные системы следуют стандартам, установленным PCI DSS в рамках Совета по стандартам безопасности PCI, совета, сформированного такими брендами, как Visa, Mastercard, American Express и Discover. Требования PCI DSS помогают обеспечить безопасную обработку платежной информации.',
    label_privacy_cooperate_payment_data2:'Платежная система, с которой мы работаем: PayPal.',
    label_privacy_cooperate_payment_data3:'Их политику конфиденциальности можно найти по адресу https://www.paypal.com/webapps/mpp/ua/privacy-full.',

    label_privacy_cooperate_link:'Ссылки на другие сайты',
    label_privacy_cooperate_link_data:'Наш Сервис может содержать ссылки на другие веб-сайты, которые не находятся под нашим управлением. Если вы нажмете на стороннюю ссылку, вы попадете на сторонний веб-сайт. Мы настоятельно рекомендуем вам ознакомиться с политикой конфиденциальности каждого веб-сайта, который вы посещаете. Мы не контролируем и не несем ответственности за контент, политику конфиденциальности или действия любых сторонних сайтов или служб.',

    label_privacy_cooperate_child:'Конфиденциальность детей',
    label_privacy_cooperate_child_data:'Мы не оказываем услуги детям до 13 лет («Дети»). Мы сознательно не собираем личную информацию от детей в возрасте до 13 лет. Если вы являетесь родителем или опекуном и знаете, что ваш ребенок предоставил нам Персональные данные, свяжитесь с нами. Если мы обнаружим, что собрали персональные данные ребенка без согласия родителей, мы предпримем шаги по удалению этой информации с наших серверов.',

    label_privacy_cooperate_policy:'Как мы уведомим вас об изменениях в этой политике?',
    label_privacy_cooperate_policy_data:'Мы можем время от времени обновлять нашу политику конфиденциальности. Мы сообщим вам о любых изменениях в нашей политике конфиденциальности, разместив новую политику конфиденциальности на этой странице. Мы уведомим вас о таких изменениях по электронной почте и/или разместим уведомление на видном месте в нашем Сервисе до того, как изменения вступят в силу, а также обновим «Дата вступления в силу» в верхней части Политики конфиденциальности. Вам рекомендуется регулярно проверять настоящую Политику конфиденциальности, чтобы узнать, произошли ли какие-либо изменения. Любые изменения в настоящей Политике конфиденциальности вступают в силу с момента их публикации на этой странице.',

    label_privacy_cooperate_connect:'Как с нами связаться?',
    label_privacy_cooperate_connect_data:'Если у вас есть какие-либо вопросы по поводу настоящей Политики конфиденциальности, свяжитесь с нами:',
    label_privacy_cooperate_connect_data1:'Электронная почта: vs.l@bozee.cn',
    label_privacy_cooperate_connect_data2:'Посетите эту страницу нашего сайта: http://www.quicksharevip.com/privacy',

}