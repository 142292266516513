//中文繁体
export default{
    title: '軟件下載',
    label_title: '下載投屏軟件客戶端',
    label_subtitle: '同時支援Windows, macOS, iOS, iPadOS, Android, Linux版本',
    label_subtitle_korea: '同時支援Windows，Android，Linux版本',
    label_reduce_subtitle: '同時支援Windows, Android版本',
    label_ok: '確定',
    label_cancel: '取消',
    label_video: '使用幫助視頻',
    label_video_subtitle: '輕鬆教您使用設備：壁紙設置、投屏碼使用等功能的使用視頻解說',
    label_video_button: '觀看更多視頻',
    label_footer:'Copyright@2023 無線投屏系統 保留所有權利 ',
    label_footer1:'粵ICP備14037583號',
    label_dowload:'點擊下載',
    label_new_dowload:'新版本下載',
    label_old_dowload:'舊版本下載',

    label_windows_title:'適用於Windows的遠程桌面軟件',
    label_windows_subtitle:'通過Windows遠程控制 Windows、macOS、Android、iOS、Linux等設備',
    label_windows_subtitle1:'兼容Window 7及以上',

    label_macos_title:'適用於Mac的遠程桌面軟件',
    label_macos_subtitle:'通過Mac遠程控制 Windows、macOS、Android、iOS、Linux等設備',
    label_macos_subtitle1:'兼容 OSX 10.10及以上',

    label_ipados_title:'適用於iOS&iPadOS的遠程桌面軟件',
    label_ipados_subtitle:'通過iOS&iPadOS遠端控制Android、iOS、iPadOS、linux等設備，輕鬆實現行動辦公',
    label_ipados_subtitle1:'兼容iOS 9.0 及以上',

    label_android_title:'適用於Android的遠程桌面軟件',
    label_android_subtitle:'通過Android遠程控制Android、linux等設備，輕鬆實現移動辦公',
    label_android_subtitle1:'兼容Android 5.1 及以上',

    label_linux_title:'適用於Linux的遠程桌面軟件',
    label_linux_subtitle:'支持Ubuntu、Deepin、CentOS、信創UOS、麒麟、中科方德等系統',
    label_linux_subtitle1:'兼容Ubuntu 64位 16.0及以上',


    label_privacy_title:'隱私權說明',
    label_privacy_policy_title:'隱私權政策',
    label_privacy_policy_date:'生效日期：2019年10月17日',
    label_privacy_policy_body1:'深圳市寶澤科技有限公司(Shenzhen Bozee Technology Co., Ltd)（"我們"）經營 http://www.quicksharevip.com/privacy相關網站、quichkshare行動應用程式（"服務"）。',
    label_privacy_policy_body2:'當您使用我們的服務以及您與該資料相關的選擇時，該頁面會通知您有關收集、使用和揭露個人資料的政策。',
    label_privacy_policy_body3:'我們使用您的數據來提供和改進服務。 透過使用本服務，您同意根據該政策收集和使用資訊。 除本隱私權政策另有規定外，本隱私權政策使用的術語與我們的條款和條件使用的同一術語具有相同的含義。',

    label_privacy_definition:'定義',
    label_privacy_definition_personal:'個人資料',
    label_privacy_definition_personal_data:'個人資料是指可以從這些資料（或從我們擁有的或我們可能擁有的其他資訊）中識別出某個人的此類資料。',
    label_privacy_definition_usage:'使用數據',
    label_privacy_definition_usage_data:'使用資料是透過使用服務或從服務基礎設施本身產生的資料（例如，頁面存取的持續時間）自動收集的資料。',
    label_privacy_definition_cookies:'Cookies',
    label_privacy_definition_cookies_data:'Cookies是儲存在使用者裝置上的一小段資料。',
    label_privacy_definition_controller:'數據控制人',
    label_privacy_definition_controller_data:'資料控制人是指（單獨或共同或與他人共同）決定個人資料現在或未來的用途及處理方式的人。',
    label_privacy_definition_processor:'資料處理人（或服務提供者）',
    label_privacy_definition_processor_data1:'資料處理人（或服務提供者）是指代表資料控制人處理資料的任何人（資料控制人的員工除外）。',
    label_privacy_definition_processor_data2:'為了更有效地處理您的數據，我們可以使用各種服務提供者的服務。',
    label_privacy_definition_subject:'資料主體',
    label_privacy_definition_subject_data:'資料主體是指作為個人資料主體的任何人。',
    label_privacy_definition_user:'使用者',
    label_privacy_definition_user_data:'使用者是使用我們服務的個人。 使用者對應於資料主體，即個人資料的主體。',


    label_privacy_collect:'我們如何使用這些資訊？',
    label_privacy_collect_personal:'個人資料',
    label_privacy_collect_personal_data:'當您使用我們的服務時，我們可能會要求您提供一些個人識別信息，這些信息可以用來聯繫您或識別您（「個人資料」）。 個人識別資訊可能包括但不限於：',
    label_privacy_collect_personal_data_mail:'電子郵件地址',
    label_privacy_collect_personal_data_name:'姓名',
    label_privacy_collect_personal_data_tel:'電話號碼',
    label_privacy_collect_personal_data_address:'地址，國家，省份，郵編，城市',
    label_privacy_collect_personal_data_cookiesAusage:'Cookies和使用數據',
    label_privacy_collect_personal_data1:'我們可以使用您的個人資料傳送您可能感興趣的電子報、行銷或宣傳資料和其他資訊給您。 您可以按照我們發送的任何電子郵件中提供的取消訂閱連結或說明，選擇不接受來自我們的任何或所有這些通訊。',
    label_privacy_collect_usage:'使用數據',
    label_privacy_collect_usage_data1:'您存取我們的服務或透過行動裝置存取我們的服務時，我們也可以收集您的瀏覽器所發送的資訊（「使用資料」）。',
    label_privacy_collect_usage_data2:'此使用資料包括您的電腦的網際網路通訊協定位址（例如IP位址）、瀏覽器類型、瀏覽器版本、您造訪的服務頁面、收藏夾、觀看的影片、喜愛的影片、影片播放清單、您造訪的時間 和日期、花費在這些頁面上的時間、唯一裝置識別碼和其他診斷資料等資訊。',
    label_privacy_collect_usage_data3:'當您透過行動裝置存取服務時，該使用資料包括您使用的行動裝置的類型、您的行動裝置的唯一ID、您的行動裝置的IP位址、您的行動作業系統、您使用的行動網路瀏覽 器的類型、唯一設備識別碼和其他診斷資料等資訊。',
    label_privacy_collect_device:'設備管理',
    label_privacy_collect_device_data:'我們需要收集您的設備型號、唯一設備識別碼（安卓端為Android ID，iOS端為UUID），用於管理各終端設備，並在使用新設備時進行登入認證。',
    label_privacy_collect_security:'安全保障',
    label_privacy_collect_security_data:'為保障您的帳戶及交易安全，我們會依法依照網路運作安全需求收集及處理您的非可變設備識別碼（硬體序號）、可變設備識別(Android ID，IP位址，OAID)、基本設備資訊（ 設備型號、設備製造商、設備序號、手機品牌）、基本硬體資訊（IMSI, IMEI、 MAC位址）、作業系統及軟體版本資訊、設備狀態、網路狀態、懸浮窗、IDFA、UUID， 並將使用 您的權限進行存儲，我們也會將前述資訊用於預防各類非法使用或侵害合法權益的行為。',
    label_privacy_collect_location:'位置數據',
    label_privacy_collect_location_data1:'如果您允許，我們可以使用和儲存您的位置資訊(“位置資料”)。 我們使用這些數據來為您提供我們的特色服務，並改進和為您量身定制我們的服務。',
    label_privacy_collect_location_data2:'當您在任何時候使用我們的服務時，您可以透過您的裝置設定啟用或停用位置服務。',
    label_privacy_collect_internet:'網路資訊',
    label_privacy_collect_internet_data:'我們需要在使用過程中取得裝置已連線的Wi-Fi名稱（SSID），以確保網路連線服務的正常運作。',
    label_privacy_collect_devicelist:'設備列表',
    label_privacy_collect_devicelist_data:'在取得使用者授權後，應用程式會自動執行裝置搜尋功能，我們將會收集您的裝置清單信息，以完成裝置連線服務。',
    label_privacy_collect_file:'文件/圖片/視頻/音頻',
    label_privacy_collect_file_data:'在取得使用者授權後，在主動進入多媒體投影機時，我們將會收集您的檔案/圖片/視訊/音訊作為投影機內容的一部分，為您提供多媒體投影服務。',
    label_privacy_collect_cookies:'追蹤Cookies數據',
    label_privacy_collect_cookies_data1:'我們使用Cookies和類似的追蹤技術來追蹤我們服務上的活動並保存某些資訊。',
    label_privacy_collect_cookies_data2:'Cookies是具有少量資料的文件，其中可能包括一個匿名的唯一識別碼。 Cookies從網站發送到您的瀏覽器並儲存在您的裝置上。 追蹤技術還使用信標、標籤和腳本來收集和追蹤信息，並改進和分析我們的服務。',
    label_privacy_collect_cookies_data3:'您可以指示瀏覽器拒絕所有Cookies，或指示何時發送Cookies。 但是，如果您不接受Cookies，您可能無法使用我們的某些服務。 我們使用的Cookies範例：',
    label_privacy_collect_cookies_data4:'會話Cookies： 我們使用會話Cookies來運作我們的服務。',
    label_privacy_collect_cookies_data5:'偏好Cookies： 我們使用偏好Cookies來記住您的偏好和各種設定。',
    label_privacy_collect_cookies_data6:'安全Cookies： 我們使用安全Cookies來保障安全。',
    label_privacy_collect_cookies_data7:'廣告Cookies： 我們使用廣告Cookies來提供您可能與您和您的興趣有關的廣告。',
    label_privacy_collect_sensor:'感測器應用場景',
    label_privacy_collect_sensor_data:'在以下應用情境中，我們可能會呼叫您的感測器權限，透過您的感測器資訊（單獨的感測器資訊非個人資訊）為您提供對應業務功能。 請您知悉，單獨的設備感測器資訊不涉及任何個人位置資訊且無法與其他資訊結合用於識別特定自然人的身份。',
    label_privacy_collect_sensor_data1:'加速感應器、方向感應器： 圖片展示中橫豎螢幕切換，偵測螢幕方向變化，進行圖片展示橫豎螢幕切換；',
    label_privacy_collect_sensor_data2:'加速度感應器： 無線遙控器功能，辨識設備狀態以判斷使用者的特定操作；',
    label_privacy_collect_sensor_data3:'光線感應器： 掃碼功能，偵測光線以調整螢幕亮度；',
    label_privacy_collect_sensor_data4:'旋轉向量感應器、陀螺儀感應器、加速度感應器： 遠端滑鼠模擬，辨識模擬滑鼠的方向與位移；',
    label_privacy_collect_sensor_data5:'加速度感測器、磁場感測器、方向感測器、壓力感測器、重力感測器、旋轉向量感測器： 應用內定位服務等其他通用功能，經過使用者授權後，輔助取得位置資訊；',
    label_privacy_collect_other:'其他權限',
    label_privacy_collect_other_data:'您在使用本司產品對應的服務時候，可能需要呼叫或使用您的設備功能用於實現對應的業務功能。 當您使用本服務之前，您可選擇是否授權開啟下列權限：',
    label_privacy_collect_other_data1:'以相機（相機）為基礎的功能： 您可開啟相機進行相機畫面投影、拍照投影機以及掃描二維碼等操作；',
    label_privacy_collect_other_data2:'以相簿為基礎的功能： 當您進行圖片投影時，開啟允許存取相簿功能，您可從本機相簿中選擇圖片進行投影機；',
    label_privacy_collect_other_data3:'基於麥克風（錄音）的功能： 您可選擇麥克風設備來進行特定場景的語音傳輸；',
    label_privacy_collect_other_data4:'基於地理位置的功能： 您可開啟定位服務，以方便應用實現偵測Wi-Fi狀態等功能；',
    label_privacy_collect_other_data5:'基於讀取手機儲存空間的功能： 您可開啟讀取手機儲存空間的權限，並已完成投送本機相簿、文件、音訊檔案等操作；',
    label_privacy_collect_other_data6:'基於寫入外部儲存的功能： 您可開啟寫入外部儲存的權限，以完成無線快照的儲存等操作；',
    label_privacy_collect_other_data7:'基於外部文件管理權限的功能： 在部分高版本系統中，需要您開啟外部文件管理權限，以進行投送文件或投送指定相簿的操作；',

    label_privacy_how:'我們如何使用這些資訊？',
    label_privacy_bozee:'深圳市寶澤科技有限公司(Shenzhen Bozee Technology Co., Ltd)將收集的數據用於:',
    label_privacy_bozee_data1:'提供和維護我們的服務',
    label_privacy_bozee_data2:'通知您我們服務的有關更改',
    label_privacy_bozee_data3:'允許您參與我們服務的互動功能，但前提是您選擇這樣做',
    label_privacy_bozee_data4:'提供客戶支援',
    label_privacy_bozee_data5:'收集分析數據或重要信息，以便改善我們的服務',
    label_privacy_bozee_data6:'監控我們服務的使用情況',
    label_privacy_bozee_data7:'檢測、預防和解決技術問題',
    label_privacy_bozee_data8:'根據您已購買或查詢的商品、服務和活動，向您提供我們提供的其他類似商品、服務和活動的新聞、特別優惠和一般信息，除非您已選擇不接受此類信息',

    label_privacy_reserve:'這些資訊可保留多久？',
    label_privacy_reserve_data1:'深圳市寶澤科技有限公司(Shenzhen Bozee Technology Co., Ltd)只會在本隱私權政策規定用途所需的時間內保留您的個人資料。 我們保留和使用您的個人資料只是為了履行我們的法律義務（例如，我們保留您的資料是為了遵守適用的法律）、解決糾紛並執行我們的法律協議和政策。',
    label_privacy_reserve_data2:'深圳市寶澤科技有限公司(Shenzhen Bozee Technology Co., Ltd)也將保留使用數據，用於進行內部分析。 使用資料保留的時間通常較短，除非這些資料用於增強安全性或改善我們服務的功能，或者我們依據法律有義務將這些資料保留較長的時間。',

    label_privacy_shared:'這些資訊如何分享？',
    label_privacy_shared_data1:'您的訊息，包括個人數據，可能會被傳送到並保存在您所在州、省、國家或其他政府管轄區之外的計算機上，那裡的數據保護法可能與您管轄區的數據保護法有所 不同。 如果您在中國境外，並選擇給我們提供訊息，請注意，我們會把包括個人資料在內的資料傳送到中國並在中國處理。',
    label_privacy_shared_data2:'您同意本隱私權政策後，提交這些資訊即代表您同意該傳送。',
    label_privacy_shared_data3:'深圳市寶澤科技有限公司(Shenzhen Bozee Technology Co., Ltd)將採取一切必要的措施，確保根據本隱私權政策安全處理您的數據，除有適當控制措施（包括保障您的數據和其他個人資訊的 安全性）外，您的個人資料不會被傳送到任何組織或國家。',


    label_privacy_law:'我們如何應對法律要求？',
    label_privacy_law_disclosure:'依法揭露',
    label_privacy_law_disclosure_data:'在某些情況下,深圳市寶澤科技有限公司(Shenzhen Bozee Technology Co., Ltd)可能需要依據法律或響應公共當局（如法院或政府機構）的有效要求披露您的個人資料。',
    label_privacy_law_require:'法律要求',
    label_privacy_law_require_data:'深圳市寶澤科技有限公司(Shenzhen Bozee Technology Co., Ltd)只要有合理理由認為以下情況所需，可能會揭露您的個人資料：',
    label_privacy_law_require_data1:'遵守法律義務',
    label_privacy_law_require_data2:'保護並捍衛 深圳市寶澤科技有限公司(Shenzhen Bozee Technology Co., Ltd)的權利或財產',
    label_privacy_law_require_data3:'防止或調查與服務有關的可能不當行為',
    label_privacy_law_require_data4:'保護服務使用者和公眾的人身安全',
    label_privacy_law_require_data5:'免於承擔法律責任',


    label_privacy_safety:'您的資料有多安全？',
    label_privacy_safety_data:'您的資料的安全性對我們來說至關重要，但請注意，經由互聯網傳輸的任何方法或任何電子儲存方法均不是100%安全。 雖然我們努力使用商業上可行的方法來保護您的個人數據，但我們不能保證其絕對安全。',


    label_privacy_delete_data:'您如何管理或刪除有關您的資訊？',
    label_privacy_delete_data1:'深圳市寶澤科技有限公司(Shenzhen Bozee Technology Co., Ltd)會採取一切合理的措施，以便讓您更正、修改、刪除您的個人資料或限制其使用。',
    label_privacy_delete_data2:'如果您無法更改您的個人數據，請與我們聯繫，以便進行所需的更改。',
    label_privacy_delete_data3:'如果您想知道我們保留了您的哪些個人數據，而且您想讓我們從我們的系統中刪除這些數據，請聯絡我們。',
    label_privacy_delete_data4:'在某些情況下，您有權:',
    label_privacy_delete_data5:'糾正您的任何錯誤個人數據',
    label_privacy_delete_data6:'請求刪除您的個人數據',
    label_privacy_delete_data7:'您有權遷移您提供給深圳市寶澤科技有限公司(Shenzhen Bozee Technology Co., Ltd)的資訊。 您可以要求取得常用電子格式的個人資料副本，以便於您管理和遷移這些資料。 請注意，我們可能會先讓您證明您的身份，然後才會回應此類要求。',



    label_privacy_cooperate:'我們如何與第三方合作夥伴分享訊息',
    label_privacy_cooperate_body:'我們可以僱用第三方公司和個人（「服務提供者」）來促進我們的服務，代表我們提供服務，執行與我們的服務相關的服務，或協助我們分析我們的服務的使用情況。 這些第三方在代表我們執行這些任務時有權存取您的個人數據，並且有義務不披露這些數據或將其用於其他用途。',
    label_privacy_cooperate_analyze:'分析',
    label_privacy_cooperate_analyze_data:'我們可以使用第三方服務提供者來監控和分析我們的服務的使用。',
    label_privacy_cooperate_analyze_data1:'Google分析（Google Analytics）',
    label_privacy_cooperate_analyze_data2:'谷歌分析是由Google提供的Web分析服務，用於追蹤和報告網站流量。 谷歌使用收集到的數據來追蹤和監視我們的服務的使用。 此數據與其他Google服務共享。 谷歌可以使用收集到的數據來讓自身廣告網路上的廣告呈現情境化和個人化。',
    label_privacy_cooperate_analyze_data3:'您可以在常規瀏覽器環境中安裝谷歌分析停用瀏覽器插件或在我們的應用程式中更改設置，從而選擇停止將您在該服務上的活動提供給谷歌分析。 該插件防止谷歌分析JavaScript(ga.js, analytics.js, 和 dc.js)與谷歌分析共享訪問活動的資訊。',
    label_privacy_cooperate_analyze_data4:'有關谷歌隱私實踐的更多信息，請訪問谷歌隱私條款網頁： http://www.google.com/intl/en/policies/privacy/',
    label_privacy_cooperate_firebase:'Firebase',
    label_privacy_cooperate_firebase_data:'Firebase是由Google提供的Web分析服務，用於追蹤和報告應用程式使用情況。 谷歌使用收集到的數據來追蹤和監視我們的服務的使用。 此數據與其他Google服務共享。 谷歌可以使用收集到的數據來讓自身廣告網路上的廣告呈現情境化和個人化。',
    label_privacy_cooperate_firebase_data1:'您可以在我們的應用程式中更改設置，從而選擇停止將您在該服務上的活動提供給Google分析。',
    label_privacy_cooperate_firebase_data2:'有關谷歌隱私實踐的更多信息，請訪問谷歌隱私條款網頁： http://www.google.com/intl/en/policies/privacy/',
    label_privacy_cooperate_advertise:'廣告',
    label_privacy_cooperate_advertise_data:'我們可以使用第三方服務提供者向您發布廣告，以協助支援和維護我們的服務。',
    label_privacy_cooperate_advertise_data1:'谷歌AdSense DoubleClick Cookie',
    label_privacy_cooperate_advertise_data2:'谷歌作為第三方供應商，使用Cookie推送我們服務的廣告。 谷歌使用DoubleClick cookie使其及其合作夥伴能夠根據我們的用戶造訪我們的服務或網路上其他網站的情況向我們的用戶推送廣告。',
    label_privacy_cooperate_advertise_data3:'您可以造訪Google廣告設定網頁： http://www.google.com/ads/preferences/ ，選擇退出使用基於興趣的廣告的DoubleClick Cookie',
    label_privacy_cooperate_advertise_data4:'GoogleAdMob',
    label_privacy_cooperate_advertise_data5:'GoogleAdMob 由Google公司提供。',
    label_privacy_cooperate_advertise_data6:'您可以選擇退出GoogleAdMob服務，具體操作請參閱Google說明： https://support.google.com/ads/answer/2662922?hl=en',
    label_privacy_cooperate_advertise_data7:'有關谷歌如何使用所收集信息的更多信息，請訪問“谷歌在您使用我們的合作夥伴的站點或應用程序時如何使用數據”頁面： http://www.google.com/policies/privacy/partners / 或造訪Google的隱私權政策： http://www.google.com/policies/privacy/',
    label_privacy_cooperate_behavior:'行為再行銷',
    label_privacy_cooperate_behavior_data:'在您造訪我們的服務後,深圳市寶澤科技有限公司(Shenzhen Bozee Technology Co., Ltd)使用再行銷服務在第三方網站上向您推播廣告。 我們和我們的第三方供應商根據您過去造訪我們的服務的數據，使用Cookies來通知、優化和推播廣告。',
    label_privacy_cooperate_behavior_data1:'Google AdWords',
    label_privacy_cooperate_behavior_data2:'GoogleAdWords再行銷服務由Google公司提供。 您可以退出Google分析顯示廣告，並透過造訪Google 廣告設定頁面：http://www.google.com/settings/ads 來客製化Google顯示網路廣告',
    label_privacy_cooperate_behavior_data3:'谷歌也建議為您的網頁瀏覽器安裝Google分析選擇退出瀏覽器外掛程式（Google Analytics Opt-out Browser Add-on）- https://tools.google.com/dlpage/gaoptout 。 谷歌分析選擇退出瀏覽器外掛程式可讓訪客防止Google分析收集和使用其資料。',
    label_privacy_cooperate_behavior_data4:'有關谷歌隱私實踐的更多信息，請訪問谷歌隱私條款網頁： http://www.google.com/intl/en/policies/privacy/',


    label_privacy_cooperate_payment:'付款',
    label_privacy_cooperate_payment_data:'我們可以在服務範圍內提供有償產品和/或服務。 對於這種情況，我們使用第三方付款處理服務（如付款處理方）。',
    label_privacy_cooperate_payment_data1:'我們不會儲存或收集您的支付卡的資訊。 這些資訊直接提供給我們的第三方付款處理方，他們會根據他們的隱私權政策使用您的個人資訊。 這些支付處理方遵循PCI DSS在PCI安全標準理事會（由Visa、萬事達、美國運通和Discover等品牌共同成立的一個理事會）管理下設定的標準。 PCI DSS要求有助於確保安全處理付款資訊。',
    label_privacy_cooperate_payment_data2:'與我們合作的付款處理方是：PayPal',
    label_privacy_cooperate_payment_data3:'有關他們的隱私權政策，請造訪 https://www.paypal.com/webapps/mpp/ua/privacy-full',

    label_privacy_cooperate_link:'連結到其他網站',
    label_privacy_cooperate_link_data:'我們的服務可能包含並非由我們運作的其他網站連結。 如果您點擊了第三方鏈接，您將進入第三方網站。 我們強烈建議您查閱您造訪的每個網站的隱私權政策。 我們無法控制任何第三方網站或服務的內容、隱私權政策或實踐，也不承擔任何相關責任。',

    label_privacy_cooperate_child:'兒童隱私',
    label_privacy_cooperate_child_data:'我們不提供13歲以下兒童（「兒童」）服務。 我們不會有意收集13歲以下兒童的個人識別資訊。 如果您是家長或監護人，並且您知道您的孩子向我們提供了個人數據，請與我們聯繫。 如果我們發現我們未經家長同意收集了兒童的個人數據，我們會採取措施從我們的伺服器中刪除這些資訊。',

    label_privacy_cooperate_policy:'我們如何告知您本政策的變更？',
    label_privacy_cooperate_policy_data:'我們可能會不時地更新我們的隱私權政策。 我們會在本頁面發布新的隱私權政策，以將隱私權政策的任何變更告知您。 在變更生效之前，我們將透過電子郵件和/或在我們服務的顯眼地方發布通知，以告知您此類變更，並在隱私權政策的頂部更新「生效日期」。 建議您定期查看本隱私權政策，以了解是否有任何變更。 本隱私權政策的任何變更在本頁面公佈時開始生效。',

    label_privacy_cooperate_connect:'如何聯絡我們？',
    label_privacy_cooperate_connect_data:'如果您對本隱私權政策有任何疑問，請與我們聯絡：',
    label_privacy_cooperate_connect_data1:'電子郵件：vs.l@bozee.cn',
    label_privacy_cooperate_connect_data2:'請造訪我們網站的此頁面： http://www.quicksharevip.com/privacy',
}