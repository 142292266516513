export const pcRoutes = [
    {
        path: '/',
        name: 'Dowload',
        // component: () => import("../../views/Dowload.vue"),// 公共下载地址
        component: () => import("../../views/Android.vue"),//Android下载地址
        // component: () => import("../../views/ReduceDowload.vue"),//精简版下载地址
        // component: () => import("../../views/KoreaDowload.vue"),//韩国客户下载地址
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import("../../views/Privacy.vue"),// 隐私说明页面
    },
]