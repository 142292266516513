//中文简体
export default{
    title: '软件下载',
    label_title: '下载投屏软件客户端',
    label_subtitle: '同时支持Windows, macOS, iOS, iPadOS, Android, Linux版本',
    label_subtitle_korea: '同时支持Windows, Android, Linux版本',
    label_reduce_subtitle: '同时支持Windows, Android版本',
    label_ok: '确定',
    label_cancel: '取消',
    label_video: '使用帮助视频',
    label_video_subtitle: '轻松教您使用设备：壁纸设置、投屏码使用等功能的使用视频解说',
    label_video_button: '观看更多视频',
    label_footer:'Copyright@2023 无线投屏系统 保留所有权利 ',
    label_footer1:'粤ICP备14037583号',
    label_dowload:'点击下载',
    label_new_dowload:'新版本下载',
    label_old_dowload:'旧版本下载',

    label_windows_title:'适用于Windows的远程桌面软件',
    label_windows_subtitle:'通过Windows远程控制 Windows、macOS、Android、iOS、Linux等设备',
    label_windows_subtitle1:'兼容Window 7及以上',


    label_macos_title:'适用于Mac的远程桌面软件',
    label_macos_subtitle:'通过Mac远程控制 Windows、macOS、Android、iOS、Linux等设备',
    label_macos_subtitle1:'兼容 OSX 10.10及以上',

    label_ipados_title:'适用于iOS&iPadOS的远程桌面软件',
    label_ipados_subtitle:'通过iOS&iPadOS远程控制Android、iOS、iPadOS、linux等设备，轻松实现移动办公',
    label_ipados_subtitle1:'兼容iOS 9.0 及以上',

    label_android_title:'适用于Android的远程桌面软件',
    label_android_subtitle:'通过Android远程控制Android、linux等设备，轻松实现移动办公',
    label_android_subtitle1:'兼容Android 5.1 及以上',

    label_linux_title:'适用于Linux的远程桌面软件',
    label_linux_subtitle:'支持Ubuntu、Deepin、CentOS、信创UOS、麒麟、中科方德等系统',
    label_linux_subtitle1:'兼容Ubuntu 64位 16.0及以上',


    label_privacy_title:'隐私说明',
    label_privacy_policy_title:'隐私政策',
    label_privacy_policy_date:'生效日期：2019年10月17日',
    label_privacy_policy_body1:'深圳市宝泽科技有限公司(Shenzhen Bozee Technology Co., Ltd)（"我们"）运营 http://www.quicksharevip.com/privacy相关网站、quichkshare移动应用程序（"服务"）。',
    label_privacy_policy_body2:'当您使用我们的服务以及您与该数据相关的选择时，该页面会通知您有关收集、使用和披露个人数据的政策。',
    label_privacy_policy_body3:'我们使用您的数据来提供和改进服务。通过使用该服务，您同意根据该政策收集和使用信息。除本隐私政策另有规定外，本隐私政策使用的术语与我们的条款和条件使用的同一术语具有相同的含义。',

    label_privacy_definition:'定义',
    label_privacy_definition_personal:'个人数据',
    label_privacy_definition_personal_data:'个人数据是指可以从这些数据（或者从我们拥有的或我们可能拥有的其他信息）中识别出某个人的此类数据。',
    label_privacy_definition_usage:'使用数据',
    label_privacy_definition_usage_data:'使用数据是通过使用服务或从服务基础设施本身生成的数据（例如，页面访问的持续时间）自动收集的数据。',
    label_privacy_definition_cookies:'Cookies',
    label_privacy_definition_cookies_data:'Cookies是存储在用户设备上的一小段数据。',
    label_privacy_definition_controller:'数据控制人',
    label_privacy_definition_controller_data:'数据控制人是指（单独或联合或与他人共同）决定个人数据现在或将来的用途及处理方式的人。',
    label_privacy_definition_processor:'数据处理人（或服务提供商）',
    label_privacy_definition_processor_data1:'数据处理人（或服务提供商）是指代表数据控制人处理数据的任何人（数据控制人的雇员除外）。',
    label_privacy_definition_processor_data2:'为了更有效地处理您的数据，我们可以使用各种服务提供商的服务。',
    label_privacy_definition_subject:'数据主体',
    label_privacy_definition_subject_data:'数据主体是指作为个人数据主体的任何人。',
    label_privacy_definition_user:'用户',
    label_privacy_definition_user_data:'用户是使用我们服务的个人。用户对应于数据主体，即个人数据的主体。',


    label_privacy_collect:'我们如何使用这些信息？',
    label_privacy_collect_personal:'个人数据',
    label_privacy_collect_personal_data:'在您使用我们的服务时，我们可能会要求您提供一些个人身份信息，这些信息可以用来联系您或识别您（“个人数据”）。个人身份信息可能包括但不限于：',
    label_privacy_collect_personal_data_mail:'电子邮件地址',
    label_privacy_collect_personal_data_name:'姓名',
    label_privacy_collect_personal_data_tel:'电话号码',
    label_privacy_collect_personal_data_address:'地址，国家，省份，邮编，城市',
    label_privacy_collect_personal_data_cookiesAusage:'Cookies和使用数据',
    label_privacy_collect_personal_data1:'我们可以使用您的个人数据给您发送您可能感兴趣的时事通讯、营销或宣传材料和其他信息。您可以按照我们发送的任何电子邮件中提供的退订链接或说明，选择不接受来自我们的任何或所有这些通信。',
    label_privacy_collect_usage:'使用数据',
    label_privacy_collect_usage_data1:'当您访问我们的服务或当您通过移动设备访问我们的服务时，我们也可以收集您的浏览器发送的信息（“使用数据”）。',
    label_privacy_collect_usage_data2:'该使用数据包括您的计算机的互联网协议地址（例如IP地址）、浏览器类型、浏览器版本、您访问的服务页面、收藏夹、观看的视频、喜爱的视频、视频播放列表、您访问的时间和日期、花费在这些页面上的时间、唯一设备标识符和其他诊断数据等信息。',
    label_privacy_collect_usage_data3:'当您通过移动设备访问该服务时，该使用数据包括您使用的移动设备的类型、您的移动设备的唯一ID、您的移动设备的IP地址、您的移动操作系统、您使用的移动互联网浏览器的类型、唯一设备标识符和其他诊断数据等信息。',
    label_privacy_collect_device:'设备管理',
    label_privacy_collect_device_data:'我们需要收集您的设备型号、唯一设备标识符（安卓端为Android ID，iOS端为UUID），用于管理各终端设备，并在使用新设备时进行登录认证。',
    label_privacy_collect_security:'安全保障',
    label_privacy_collect_security_data:'为保障您的账户及交易安全，我们会依法根据网络运行安全需要收集和处理您的 不可变设备标识（硬件序列号）、可变设备标识(Android ID，IP地址，OAID)、基本设备信息（设备型号、设备制造商、设备序列号、手机品牌）、基本硬件信息（IMSI, IMEI、 MAC地址）、操作系统及软件版本信息、设备状态、网络状态、悬浮窗、IDFA、UUID， 并将使用您的权限进行存储，我们也会将前述信息用于预防各类非法使用或侵害合法权益的行为。',
    label_privacy_collect_location:'位置数据',
    label_privacy_collect_location_data1:'如果您允许，我们可以使用和存储您的位置信息(“位置数据”)。我们使用这些数据来为您提供我们的特色服务，并改进和为您定制我们的服务。',
    label_privacy_collect_location_data2:'当您在任何时候使用我们的服务时，您可以通过您的设备设置启用或禁用位置服务。',
    label_privacy_collect_internet:'网络信息',
    label_privacy_collect_internet_data:'我们需要在使用过程中获取设备已连接的Wi-Fi名称（SSID），用于确保网络连接服务的正常运行。',
    label_privacy_collect_devicelist:'设备列表',
    label_privacy_collect_devicelist_data:'在获取用户授权后，应用会自动运行设备搜索功能，我们将会收集您的设备列表信息，以完成设备连接服务。',
    label_privacy_collect_file:'文件/图片/视频/音频',
    label_privacy_collect_file_data:'在获取用户授权后，在主动进入多媒体投屏时，我们将会收集您的文件/图片/视频/音频作为投屏内容的一部分，为您提供多媒体投屏服务。',
    label_privacy_collect_cookies:'跟踪Cookies数据',
    label_privacy_collect_cookies_data1:'我们使用Cookies和类似的跟踪技术来跟踪我们服务上的活动并保存某些信息。',
    label_privacy_collect_cookies_data2:'Cookies是具有少量数据的文件，其中可能包括一个匿名的唯一标识符。Cookies从网站发送到您的浏览器并存储在您的设备上。跟踪技术还使用信标、标签和脚本来收集和跟踪信息，并改进和分析我们的服务。',
    label_privacy_collect_cookies_data3:'您可以指示浏览器拒绝所有Cookies，或指示何时发送Cookies。但是，如果您不接受Cookies，您可能无法使用我们的某些服务。我们使用的Cookies例子：',
    label_privacy_collect_cookies_data4:'会话Cookies：   我们使用会话Cookies来运作我们的服务。',
    label_privacy_collect_cookies_data5:'偏好Cookies：   我们使用偏好Cookies来记住您的偏好和各种设置。',
    label_privacy_collect_cookies_data6:'安全Cookies：   我们使用安全Cookies来保障安全。',
    label_privacy_collect_cookies_data7:'广告Cookies：   我们使用广告Cookies来为您提供可能与您和您的兴趣有关的广告。',
    label_privacy_collect_sensor:'传感器应用场景',
    label_privacy_collect_sensor_data:'在如下应用场景中，我们可能会调用您的传感器权限，通过您的传感器信息（单独的传感器信息非个人信息）为您提供相应业务功能。请您知悉，单独的设备传感器信息不涉及任何个人位置信息且无法与其他信息结合用于识别特定自然人的身份。',
    label_privacy_collect_sensor_data1:'加速度传感器、方向传感器：   图片展示中横竖屏切换，检测屏幕方向变化，进行图片展示横竖屏切换；',
    label_privacy_collect_sensor_data2:'加速度传感器：   无线遥控器功能，识别设备状态以判断用户的具体操作；',
    label_privacy_collect_sensor_data3:'光线传感器：   扫码功能，检测光线以调整屏幕亮度；',
    label_privacy_collect_sensor_data4:'旋转矢量传感器、陀螺仪传感器、加速度传感器：   远程鼠标模拟，识别模拟鼠标的方向与位移；',
    label_privacy_collect_sensor_data5:'加速度传感器、磁场传感器、方向传感器、压力传感器、重力传感器、旋转矢量传感器：   应用内定位服务等其他通用功能，经过用户授权后，辅助获得位置信息；',
    label_privacy_collect_other:'其他权限',
    label_privacy_collect_other_data:'您在使用本司产品对应的服务时候，可能需要调用或者使用您的设备功能用于实现对应的业务功能。当您使用服务之前，您可选择是否授权开通以下权限：',
    label_privacy_collect_other_data1:'基于摄像头（相机）的功能：   您可开启摄像头进行相机画面投屏、拍照投屏以及扫描二维码等操作；',
    label_privacy_collect_other_data2:'基于相册的功能：   当您进行图片投屏时，通过开启允许访问相册功能，您可从本地相册中选择图片进行投屏；',
    label_privacy_collect_other_data3:'基于麦克风（录音）的功能：   您可选择麦克风设备来进行特定场景的语音传输；',
    label_privacy_collect_other_data4:'基于地理位置的功能：   您可开启定位服务，以方便应用实现检测Wi-Fi状态等功能；',
    label_privacy_collect_other_data5:'基于读取手机存储空间的功能：   您可开启读取手机存储空间的权限，已完成投送本机相册、文档、音频文件等操作；',
    label_privacy_collect_other_data6:'基于写入外部存储的功能：   您可开启写入外部存储的权限，以完成无线快照的保存等操作；',
    label_privacy_collect_other_data7:'基于外部文件管理权限的功能：   在部分高版本系统中，需要您开启外部文件管理权限，以进行投送文档或者投送指定相册的操作；',

    label_privacy_how:'我们如何使用这些信息？',
    label_privacy_bozee:'深圳市宝泽科技有限公司(Shenzhen Bozee Technology Co., Ltd)将收集的数据用于:',
    label_privacy_bozee_data1:'提供和维护我们的服务',
    label_privacy_bozee_data2:'通知您我们服务的有关更改',
    label_privacy_bozee_data3:'允许您参与我们服务的互动功能，但前提是您选择这样做',
    label_privacy_bozee_data4:'提供客户支持',
    label_privacy_bozee_data5:'收集分析数据或重要信息，以便改进我们的服务',
    label_privacy_bozee_data6:'监控我们服务的使用情况',
    label_privacy_bozee_data7:'检测、预防和解决技术问题',
    label_privacy_bozee_data8:'根据您已购买或查询的商品、服务和活动，向您提供我们提供的其他类似商品、服务和活动的新闻、特别优惠和一般信息，除非您已选择不接受此类信息',

    label_privacy_reserve:'这些信息可保留多长时间？',
    label_privacy_reserve_data1:'深圳市宝泽科技有限公司(Shenzhen Bozee Technology Co., Ltd)只会在本隐私政策规定用途所需的时间内保留您的个人数据。我们保留和使用您的个人数据只是为了履行我们的法律义务（例如，我们保留您的数据是为了遵守适用的法律）、解决纠纷并执行我们的法律协议和政策。',
    label_privacy_reserve_data2:'深圳市宝泽科技有限公司(Shenzhen Bozee Technology Co., Ltd)还将保留使用数据，用于进行内部分析。使用数据保留的时间通常较短，除非这些数据用于增强安全性或改善我们服务的功能，或者我们依据法律有义务将这些数据保留较长的时间。',

    label_privacy_shared:'这些信息如何共享？',
    label_privacy_shared_data1:'您的信息，包括个人数据，可能会被传送到并保存在您所在州、省、国家或其他政府管辖区之外的计算机上，那里的数据保护法可能与您管辖区的数据保护法有所不同。如果您在中国境外，并选择给我们提供信息，请注意，我们会把包括个人数据在内的数据传送到中国并在中国处理。',
    label_privacy_shared_data2:'您同意本隐私政策后，提交这些信息即代表您同意该传送。',
    label_privacy_shared_data3:'深圳市宝泽科技有限公司(Shenzhen Bozee Technology Co., Ltd)将采取一切必要的措施，确保根据本隐私政策安全处理您的数据，除有适当控制措施（包括保障您的数据和其他个人信息的安全性）外，您的个人数据不会被传送到任何组织或国家。',


    label_privacy_law:'我们如何应对法律要求？',
    label_privacy_law_disclosure:'依法披露',
    label_privacy_law_disclosure_data:'在某些情况下,深圳市宝泽科技有限公司(Shenzhen Bozee Technology Co., Ltd)可能需要依据法律或响应公共当局（如法院或政府机构）的有效要求披露您的个人数据。',
    label_privacy_law_require:'法律要求',
    label_privacy_law_require_data:'深圳市宝泽科技有限公司(Shenzhen Bozee Technology Co., Ltd)只要有合理理由认为以下情况所需，可能会披露您的个人数据：',
    label_privacy_law_require_data1:'遵守法律义务',
    label_privacy_law_require_data2:'保护并捍卫 深圳市宝泽科技有限公司(Shenzhen Bozee Technology Co., Ltd)的权利或财产',
    label_privacy_law_require_data3:'防止或调查与服务有关的可能不当行为',
    label_privacy_law_require_data4:'保护服务用户和公众的人身安全',
    label_privacy_law_require_data5:'免于承担法律责任',


    label_privacy_safety:'您的数据有多安全？',
    label_privacy_safety_data:'您的数据的安全对我们来说至关重要，但请注意，经由互联网传输的任何方法或者任何电子存储方法均不是100%安全。虽然我们努力使用商业上可行的方法来保护您的个人数据，但我们不能保证其绝对安全。',


    label_privacy_delete_data:'您如何管理或删除有关您的信息？',
    label_privacy_delete_data1:'深圳市宝泽科技有限公司(Shenzhen Bozee Technology Co., Ltd)会采取一切合理的措施，以便让您更正、修改、删除您的个人数据或限制其使用。',
    label_privacy_delete_data2:'如果您无法更改您的个人数据，请与我们联系，以便作出所需的更改。',
    label_privacy_delete_data3:'如果您想知道我们保留了您的哪些个人数据，而且您想让我们从我们的系统中删除这些数据，请联系我们。',
    label_privacy_delete_data4:'在某些情况下，您有权:',
    label_privacy_delete_data5:'纠正您的任何错误个人数据',
    label_privacy_delete_data6:'请求删除您的个人数据',
    label_privacy_delete_data7:'您有权迁移您给深圳市宝泽科技有限公司(Shenzhen Bozee Technology Co., Ltd)提供的信息。您可以请求获得一份常用电子格式的个人数据副本，以便于您管理和迁移这些数据。请注意，我们可能会先让您证明您的身份，然后才会回应此类请求。',



    label_privacy_cooperate:'我们如何与第三方合作伙伴共享信息',
    label_privacy_cooperate_body:'我们可以雇用第三方公司和个人（“服务提供商”）来促进我们的服务，代表我们提供服务，执行与我们的服务相关的服务，或者协助我们分析我们的服务的使用情况。这些第三方在代表我们执行这些任务时有权访问您的个人数据，并且有义务不披露这些数据或将其用于其他用途。',
    label_privacy_cooperate_analyze:'分析',
    label_privacy_cooperate_analyze_data:'我们可以使用第三方服务提供商来监控和分析我们的服务的使用情况。',
    label_privacy_cooperate_analyze_data1:'谷歌分析（Google Analytics）',
    label_privacy_cooperate_analyze_data2:'谷歌分析是由谷歌提供的Web分析服务，用于跟踪和报告网站流量。谷歌使用收集到的数据来跟踪和监视我们的服务的使用情况。此数据与其他谷歌服务共享。谷歌可以使用收集到的数据来让其自身广告网络上的广告呈现情境化和个性化。',
    label_privacy_cooperate_analyze_data3:'您可以在常规浏览器环境中安装谷歌分析停用浏览器插件或在我们的应用程序中更改设置，从而选择停止将您在该服务上的活动提供给谷歌分析。该插件防止谷歌分析JavaScript(ga.js, analytics.js, 和 dc.js)与谷歌分析共享访问活动的信息。',
    label_privacy_cooperate_analyze_data4:'有关谷歌隐私实践的更多信息，请访问谷歌隐私条款网页：   http://www.google.com/intl/en/policies/privacy/',
    label_privacy_cooperate_firebase:'Firebase',
    label_privacy_cooperate_firebase_data:'Firebase是由谷歌提供的Web分析服务，用于跟踪和报告应用程序使用情况。谷歌使用收集到的数据来跟踪和监视我们的服务的使用情况。此数据与其他谷歌服务共享。谷歌可以使用收集到的数据来让其自身广告网络上的广告呈现情境化和个性化。',
    label_privacy_cooperate_firebase_data1:'您可以在我们的应用程序中更改设置，从而选择停止将您在该服务上的活动提供给谷歌分析。',
    label_privacy_cooperate_firebase_data2:'有关谷歌隐私实践的更多信息，请访问谷歌隐私条款网页： http://www.google.com/intl/en/policies/privacy/',
    label_privacy_cooperate_advertise:'广告',
    label_privacy_cooperate_advertise_data:'我们可以使用第三方服务提供商向您发布广告，以帮助支持和维护我们的服务。',
    label_privacy_cooperate_advertise_data1:'谷歌AdSense DoubleClick Cookie',
    label_privacy_cooperate_advertise_data2:'谷歌作为第三方供应商，使用Cookie推送我们服务的广告。谷歌使用DoubleClick cookie使其及其合作伙伴能够根据我们的用户访问我们的服务或互联网上其他网站的情况向我们的用户推送广告。',
    label_privacy_cooperate_advertise_data3:'您可以访问谷歌广告设置网页： http://www.google.com/ads/preferences/ ，选择退出使用基于兴趣的广告的DoubleClick Cookie',
    label_privacy_cooperate_advertise_data4:'谷歌AdMob',
    label_privacy_cooperate_advertise_data5:'谷歌AdMob 由谷歌公司提供。',
    label_privacy_cooperate_advertise_data6:'您可以选择退出谷歌AdMob服务，具体操作见谷歌说明： https://support.google.com/ads/answer/2662922?hl=en',
    label_privacy_cooperate_advertise_data7:'有关谷歌如何使用所收集信息的更多信息，请访问“谷歌在您使用我们的合作伙伴的站点或应用程序时如何使用数据”页面： http://www.google.com/policies/privacy/partners/ 或访问谷歌的隐私政策： http://www.google.com/policies/privacy/',
    label_privacy_cooperate_behavior:'行为再营销',
    label_privacy_cooperate_behavior_data:'在您访问我们的服务后,深圳市宝泽科技有限公司(Shenzhen Bozee Technology Co., Ltd)使用再营销服务在第三方网站上向您推送广告。我们和我们的第三方供应商根据您过去访问我们的服务的数据，使用Cookies来通知、优化和推送广告。',
    label_privacy_cooperate_behavior_data1:'Google AdWords',
    label_privacy_cooperate_behavior_data2:'谷歌AdWords再营销服务由谷歌公司提供。您可以退出谷歌分析显示广告，并通过访问谷歌广告设置页面： http://www.google.com/settings/ads 来定制谷歌显示网络广告',
    label_privacy_cooperate_behavior_data3:'谷歌还建议为您的Web浏览器安装谷歌分析选择退出浏览器插件（Google Analytics Opt-out Browser Add-on）-   https://tools.google.com/dlpage/gaoptout 。谷歌分析选择退出浏览器插件可让访问者防止谷歌分析收集和使用其数据。',
    label_privacy_cooperate_behavior_data4:'有关谷歌隐私实践的更多信息，请访问谷歌隐私条款网页： http://www.google.com/intl/en/policies/privacy/',


    label_privacy_cooperate_payment:'付款',
    label_privacy_cooperate_payment_data:'我们可以在服务范围内提供有偿产品和/或服务。对于这种情况，我们使用第三方付款处理服务（如付款处理方）。',
    label_privacy_cooperate_payment_data1:'我们不会存储或收集您的支付卡的信息。这些信息直接提供给我们的第三方付款处理方，他们会根据他们的隐私政策使用您的个人信息。这些支付处理方遵循PCI DSS在PCI安全标准理事会（由Visa、万事达、美国运通和Discover等品牌共同成立的一个理事会）管理下设定的标准。PCI DSS要求有助于确保安全处理支付信息。',
    label_privacy_cooperate_payment_data2:'与我们合作的付款处理方是：PayPal',
    label_privacy_cooperate_payment_data3:'有关他们的隐私政策，可访问 https://www.paypal.com/webapps/mpp/ua/privacy-full',

    label_privacy_cooperate_link:'链接到其他网站',
    label_privacy_cooperate_link_data:'我们的服务可能包含并非由我们运作的其他网站链接。如果您点击了第三方链接，您会进入第三方网站。我们强烈建议您查阅您访问的每个网站的隐私政策。我们无法控制任何第三方网站或服务的内容、隐私政策或实践，也不承担任何相关责任。',

    label_privacy_cooperate_child:'儿童隐私',
    label_privacy_cooperate_child_data:'我们不向13岁以下儿童（“儿童”）提供服务。我们不会有意收集13岁以下儿童的个人身份信息。如果您是家长或监护人，并且您知道您的孩子向我们提供了个人数据，请与我们联系。如果我们发现我们未经家长同意收集了儿童的个人数据，我们会采取措施从我们的服务器中删除这些信息。',

    label_privacy_cooperate_policy:'我们如何告知您本政策的变更？',
    label_privacy_cooperate_policy_data:'我们可能会不时地更新我们的隐私政策。我们会在本页面发布新的隐私政策，以将隐私政策的任何变更告知您。在变更生效之前，我们将通过电子邮件和/或在我们服务的显眼地方发布通知，以告知您此类变更，并在隐私政策的顶部更新“生效日期”。建议您定期查看本隐私政策，以了解是否有任何变更。本隐私政策的任何变更在本页面公布时开始生效。',

    label_privacy_cooperate_connect:'如何联系我们？',
    label_privacy_cooperate_connect_data:'如果您对本隐私政策有任何疑问，请联系我们：',
    label_privacy_cooperate_connect_data1:'电子邮件：vs.l@bozee.cn',
    label_privacy_cooperate_connect_data2:'访问我们网站的此页面： http://www.quicksharevip.com/privacy',

}