//日语
export default{
    title: 'ソフトウェアのダウンロード',
    label_title: '画面ミラーリング ソフトウェア クライアントをダウンロードする',
    label_subtitle: 'Windows、macOS、iOS、iPadOS、Android、Linux バージョンもサポート',
    label_subtitle_korea: 'Windows、Android、Linux バージョンを同時にサポート',
    label_reduce_subtitle: 'Windows と Android の両方のバージョンをサポート',
    label_ok: 'もちろん',
    label_cancel: 'キャンセル',
    label_video: 'ヘルプビデオの使用',
    label_video_subtitle: '壁紙の設定やスクリーンキャストコードの使い方などの使い方を動画でわかりやすく解説',
    label_video_button: 'もっとビデオを見る',
    label_footer:'Copyright@2023 ワイヤレススクリーンプロジェクションシステム 無断複写・転載を禁じます',
    label_footer1:'広東省 ICP No. 14037583',
    label_dowload:'クリックしてダウンロード',
    label_new_dowload:'新しいバージョンのダウンロード',
    label_old_dowload:'旧バージョンのダウンロード',

    label_windows_title:'Windows 用リモート デスクトップ ソフトウェア',
    label_windows_subtitle:'Windows、macOS、Android、iOS、Linux、その他のデバイスを Windows 経由でリモート制御',
    label_windows_subtitle1:'Windows 7以降と互換性があります',


    label_macos_title:'Mac用リモートデスクトップソフトウェア',
    label_macos_subtitle:'Windows、macOS、Android、iOS、Linux などのデバイスを Mac からリモート制御',
    label_macos_subtitle1:'OSX 10.10以降と互換性があります',

    label_ipados_title:'iOS および iPadOS 用のリモート デスクトップ ソフトウェア',
    label_ipados_subtitle:'iOS&iPadOSを介してAndroid、iOS、iPadOS、Linuxなどのデバイスをリモート制御し、モバイルオフィスを簡単に実現します',
    label_ipados_subtitle1:'iOS 9.0以降と互換性があります',

    label_android_title:'Android 用リモート デスクトップ ソフトウェア',
    label_android_subtitle:'Android、LinuxなどのデバイスをAndroid経由で遠隔制御し、モバイルオフィスを簡単に実現',
    label_android_subtitle1:'Android 5.1以降と互換性があります',

    label_linux_title:'Linux 用リモート デスクトップ ソフトウェア',
    label_linux_subtitle:'Ubuntu、Deepin、CentOS、Xinchuang UOS、Kirin、Zhongke Fangde およびその他のシステムをサポート',
    label_linux_subtitle1:'Ubuntu 64 ビット 16.0 以降と互換性があります',


    label_privacy_title:'プライバシーに関する声明',
    label_privacy_policy_title:'プライバシーポリシー',
    label_privacy_policy_date:'発効日：2019年10月17日',
    label_privacy_policy_body1:'Shenzhen Bozee Technology Co., Ltd (「当社」) は、http://www.quicksharevip.com/privacy 関連の Web サイトおよび quichkshare モバイル アプリケーション (「サービス」) を運営しています。',
    label_privacy_policy_body2:'このページでは、お客様が当社のサービスを使用する際の個人データの収集、使用、開示に関する当社のポリシーと、そのデータに関連してお客様が選択できる選択肢についてお知らせします。',
    label_privacy_policy_body3:'当社はサービスの提供と改善のためにお客様のデータを使用します。 サービスを使用すると、本ポリシーに従って情報が収集および使用されることに同意したものとみなされます。 このプライバシー ポリシーで別途定義されていない限り、このプライバシー ポリシーで使用される用語は、当社の利用規約で使用される用語と同じ意味を持ちます。',

    label_privacy_definition:'意味',
    label_privacy_definition_personal:'個人データ',
    label_privacy_definition_personal_data:'個人データとは、個人を特定できるデータ (または、当社が保有している、または当社が保有している可能性のあるその他の情報) を意味します。',
    label_privacy_definition_usage:'使用状況データ',
    label_privacy_definition_usage_data:'使用状況データは、サービスの使用を通じて自動的に収集されるデータ、またはサービス インフラストラクチャ自体から生成されるデータ (ページ訪問期間など) です。',
    label_privacy_definition_cookies:'クッキー',
    label_privacy_definition_cookies_data:'Cookie は、ユーザーのデバイスに保存される小さなデータです。',
    label_privacy_definition_controller:'データコントローラー',
    label_privacy_definition_controller_data:'データ管理者とは、個人データの現在または将来の使用および処理を（単独で、または共同で、または他者と共同で）決定する人物です。',
    label_privacy_definition_processor:'データ処理者 (またはサービスプロバイダー)',
    label_privacy_definition_processor_data1:'データ処理者 (またはサービスプロバイダー) とは、データ管理者に代わってデータを処理する個人 (データ管理者の従業員を除く) を意味します。',
    label_privacy_definition_processor_data2:'お客様のデータをより効率的に処理するために、当社はさまざまなサービスプロバイダーのサービスを利用する場合があります。',
    label_privacy_definition_subject:'データ主体',
    label_privacy_definition_subject_data:'データ主体とは、個人データの主体であるあらゆる個人を意味します。',
    label_privacy_definition_user:'ユーザー',
    label_privacy_definition_user_data:'ユーザーとは、当社のサービスを使用する個人です。 ユーザーはデータ主体、つまり個人データの主体に相当します。',


    label_privacy_collect:'この情報をどのように使用するのでしょうか?',
    label_privacy_collect_personal:'個人データ',
    label_privacy_collect_personal_data:'お客様が当社のサービスをご利用いただく際、当社はお客様への連絡または識別に使用できる特定の個人識別情報（以下「個人データ」）の提供をお願いする場合があります。 個人を特定できる情報には次のものが含まれますが、これらに限定されません。',
    label_privacy_collect_personal_data_mail:'電子メールアドレス',
    label_privacy_collect_personal_data_name:'名前',
    label_privacy_collect_personal_data_tel:'電話番号',
    label_privacy_collect_personal_data_address:'住所、国、都道府県、郵便番号、都市',
    label_privacy_collect_personal_data_cookiesAusage:'Cookie と使用状況データ',
    label_privacy_collect_personal_data1:'当社は、ニュースレター、マーケティングまたは販促資料、およびお客様が興味を持つ可能性のあるその他の情報を送信するためにお客様の個人データを使用する場合があります。 お客様は、当社が送信する電子メールに記載されている購読解除リンクまたは手順に従って、当社からのこれらの通信の一部またはすべての受信をオプトアウトすることができます。',
    label_privacy_collect_usage:'使用状況データ',
    label_privacy_collect_usage_data1:'当社は、お客様が当社のサービスにアクセスしたとき、またはモバイルデバイスから当社のサービスにアクセスしたときにブラウザが送信する情報 (「使用状況データ」) を収集する場合もあります。',
    label_privacy_collect_usage_data2:'この使用状況データには、コンピュータのインターネット プロトコル アドレス (IP アドレスなど)、ブラウザの種類、ブラウザのバージョン、訪問したサービスのページ、お気に入り、視聴したビデオ、お気に入りのビデオ、ビデオ プレイリスト、訪問時間、および次のような情報が含まれます。日付、これらのページに費やした時間、一意のデバイス識別子、およびその他の診断データ。',
    label_privacy_collect_usage_data3:'モバイル デバイス経由でサービスにアクセスする場合、この使用状況データには、使用するモバイル デバイスの種類、モバイル デバイスの一意の ID、モバイル デバイスの IP アドレス、モバイル オペレーティング システム、および使用するモバイル インターネットの閲覧方法などの情報が含まれます。デバイスのタイプ、一意のデバイス識別子、その他の診断データなど。',
    label_privacy_collect_device:'端末管理',
    label_privacy_collect_device_data:'各端末の管理や新規端末利用時のログイン認証を行うために、お客様の端末機種と端末固有の識別子（Androidの場合はAndroid ID、iOSの場合はUUID）を収集する必要があります。',
    label_privacy_collect_security:'安全',
    label_privacy_collect_security_data:'お客様のアカウントと取引のセキュリティを確保するために、当社はお客様の不変デバイス ID (ハードウェア シリアル番号)、可変デバイス ID (Android ID、IP アドレス、OAID)、基本的なデバイス情報 (デバイス モデル、デバイス メーカー、デバイスのシリアル番号、携帯電話のブランド）、基本的なハードウェア情報（IMSI、IMEI、MAC アドレス）、オペレーティング システムとソフトウェアのバージョン情報、デバイスのステータス、ネットワーク ステータス、フローティング ウィンドウ、IDFA、UUID、および使用される許可が保存されている場合, また、上記の情報は、各種不正利用や正当な権利利益の侵害を防止するために利用させていただきます。',
    label_privacy_collect_location:'位置データ',
    label_privacy_collect_location_data1:'お客様の許可を得て、当社はお客様の位置情報 (「位置データ」) を使用および保存する場合があります。 当社はこのデータを使用して、当社の注目サービスをお客様に提供し、当社のサービスをお客様に合わせて改善およびカスタマイズします。',
    label_privacy_collect_location_data2:'当社のサービスを使用するときは、いつでもデバイスの設定を通じて位置情報サービスを有効または無効にすることができます。',
    label_privacy_collect_internet:'インターネット情報',
    label_privacy_collect_internet_data:'ネットワーク接続サービスが正常に動作するように、使用中に接続されているデバイスの Wi-Fi 名 (SSID) を取得する必要があります。',
    label_privacy_collect_devicelist:'デバイスリスト',
    label_privacy_collect_devicelist_data:'ユーザー認証を取得した後、アプリケーションは自動的にデバイス検索機能を実行し、デバイスリスト情報を収集してデバイス接続サービスを完了します。',
    label_privacy_collect_file:'ファイル/写真/ビデオ/オーディオ',
    label_privacy_collect_file_data:'ユーザーの承認を得た後、マルチメディア スクリーンキャストを積極的に開始すると、マルチメディア スクリーンキャスト サービスを提供するために、スクリーンキャスト コンテンツの一部としてファイル/写真/ビデオ/オーディオが収集されます。',
    label_privacy_collect_cookies:'Cookieデータの追跡',
    label_privacy_collect_cookies_data1:'当社は、Cookie および同様の追跡技術を使用して、サービス上のアクティビティを追跡し、特定の情報を保存します。',
    label_privacy_collect_cookies_data2:'Cookie は少量のデータを含むファイルであり、匿名の一意の識別子が含まれる場合があります。 Cookie は Web サイトからブラウザに送信され、デバイスに保存されます。 追跡テクノロジーでは、ビーコン、タグ、スクリプトも使用して、情報を収集および追跡し、当社のサービスを改善および分析します。',
    label_privacy_collect_cookies_data3:'すべての Cookie を拒否するか、Cookie がいつ送信されるかを示すようにブラウザに指示できます。 ただし、Cookieを受け入れない場合、一部のサービスがご利用いただけない場合がございます。 当社が使用する Cookie の例:',
    label_privacy_collect_cookies_data4:'セッション Cookie: 当社はサービスを運用するためにセッション Cookie を使用します。',
    label_privacy_collect_cookies_data5:'設定 Cookie: お客様の設定やさまざまな設定を記憶するために、設定 Cookie を使用します。',
    label_privacy_collect_cookies_data6:'セキュリティ Cookie: セキュリティのためにセキュリティ Cookie を使用します。',
    label_privacy_collect_cookies_data7:'広告 Cookie: 当社は、お客様およびお客様の興味に関連する可能性のある広告を提供するために広告 Cookie を使用します。',
    label_privacy_collect_sensor:'センサーの応用シナリオ',
    label_privacy_collect_sensor_data:'次のアプリケーション シナリオでは、センサーのアクセス許可を呼び出して、センサー情報 (個人情報ではなく別のセンサー情報) を通じて対応するビジネス機能を提供する場合があります。 デバイスのセンサー情報だけでは個人の位置情報は含まれず、他の情報と組み合わせて特定の自然人を識別することはできませんのでご注意ください。',
    label_privacy_collect_sensor_data1:'加速度センサー、方向センサー：映像表示中の横画面と縦画面の切り替え、画面の向きの変化を検出、映像表示時の横画面と縦画面の切り替えを行います。',
    label_privacy_collect_sensor_data2:'加速度センサー：デバイスの状態を識別してユーザーの特定の操作を決定するワイヤレスリモートコントロール機能。',
    label_privacy_collect_sensor_data3:'光センサー: 光を検出して画面の明るさを調整するスキャンコード機能。',
    label_privacy_collect_sensor_data4:'回転ベクトル センサー、ジャイロスコープ センサー、加速度センサー: リモート マウス シミュレーション、シミュレートされたマウスの方向と変位を識別します。',
    label_privacy_collect_sensor_data5:'加速度センサー、磁場センサー、方向センサー、圧力センサー、重力センサー、回転ベクトルセンサー: アプリケーション内測位サービスおよびその他の一般的な機能は、ユーザーの承認後に位置情報を取得するのに役立ちます。',
    label_privacy_collect_other:'その他の権限',
    label_privacy_collect_other_data:'当社の製品に対応するサービスを使用する場合、対応するビジネス機能を実装するために、デバイスの機能を呼び出すか使用する必要がある場合があります。 サービスを使用する前に、次の権限を承認するかどうかを選択できます',
    label_privacy_collect_other_data1:'カメラ (カメラ) ベースの機能: カメラをオンにして、カメラ画面のミラーリング、写真の撮影、QR コードのスキャンなどの操作を実行できます。',
    label_privacy_collect_other_data2:'アルバムベースの機能: 画像を画面にキャストするときに、アルバムへのアクセスを許可する機能をオンにすると、ローカル アルバムから画像を選択して画面にキャストできます。',
    label_privacy_collect_other_data3:'マイク (録音) ベースの機能: 特定のシナリオで音声送信用のマイク デバイスを選択できます。',
    label_privacy_collect_other_data4:'地理位置情報ベースの機能: 位置情報サービスをオンにして、アプリケーションが Wi-Fi ステータスやその他の機能を検出しやすくすることができます。',
    label_privacy_collect_other_data5:'携帯電話のストレージスペースを読み取る機能に基づいて: 携帯電話のストレージスペースを読み取る許可を有効にし、ローカルのフォトアルバム、ドキュメント、オーディオファイルなどの配信などの操作を完了できます。',
    label_privacy_collect_other_data6:'外部ストレージへの書き込みに基づく機能: 外部ストレージへの書き込み権限を有効にして、ワイヤレス スナップショットの保存などの操作を完了できます。',
    label_privacy_collect_other_data7:'外部ファイル管理権限に基づく機能: 一部の上位バージョン システムでは、ドキュメントを配信したり、指定したアルバムを配信したりするには、外部ファイル管理権限を有効にする必要があります。',

    label_privacy_how:'この情報をどのように使用するのでしょうか?',
    label_privacy_bozee:'Shenzhen Bozee Technology Co., Ltd は、収集されたデータを次の目的で使用します。',
    label_privacy_bozee_data1:'当社のサービスを提供および維持するため',
    label_privacy_bozee_data2:'当社のサービスの変更について通知するため',
    label_privacy_bozee_data3:'お客様が当社サービスのインタラクティブ機能に参加できるようにしますが、これはお客様が選択した場合に限ります。',
    label_privacy_bozee_data4:'カスタマーサポートを提供する',
    label_privacy_bozee_data5:'サービスを向上させるために分析データや重要な情報を収集する',
    label_privacy_bozee_data6:'当社のサービスの使用状況を監視する',
    label_privacy_bozee_data7:'技術的な問題を検出、防止、解決する',
    label_privacy_bozee_data8:'お客様がそのような情報を受け取らないことを選択した場合を除き、お客様が購入または問い合わせた商品、サービス、イベントに基づいて当社が提供する他の同様の商品、サービス、イベントに関するニュース、特別オファー、一般情報を提供するため',

    label_privacy_reserve:'この情報はどのくらいの期間保持されますか?',
    label_privacy_reserve_data1:'Shenzhen Bozee Technology Co., Ltd は、このプライバシー ポリシーに指定された目的に必要な期間のみお客様の個人データを保持します。 当社は、法的義務を遵守し（たとえば、適用される法律を遵守するためにお客様のデータを保持します）、紛争を解決し、当社の法的契約とポリシーを執行するためにのみ、お客様の個人データを保持および使用します。',
    label_privacy_reserve_data2:'Shenzhen Bozee Technology Co., Ltd も内部分析のために使用状況データを保持します。 使用状況データは、データがセキュリティの強化やサービスの機能向上のために使用される場合、またはデータを長期間保持することが法的に義務付けられている場合を除き、通常、より短期間保持されます。',

    label_privacy_shared:'この情報はどのように共有されますか?',
    label_privacy_shared_data1:'個人データを含むお客様の情報は、データ保護法がお客様の管轄区域のデータ保護法と異なる場合がある州、地方、国、またはその他の政府管轄区域外のコンピューターに転送され、保存される場合があります。 あなたが中国国外にお住まいで、当社に情報を提供することを選択した場合、当社は個人データを含むデータを中国に転送し、中国で処理することにご注意ください。',
    label_privacy_shared_data2:'この情報を送信することにより、このプライバシー ポリシーに同意したことにより、その転送に同意したことになります。',
    label_privacy_shared_data3:'Shenzhen Bozee Technology Co., Ltd は、適切な管理 (データおよびその他の個人情報の保護を含む) が実施されていない限り、お客様のデータが安全に処理されることを保証するために、このプライバシー ポリシーに従って必要なあらゆる措置を講じます。 ）、あなたの個人データはいかなる組織や国にも転送されません。',


    label_privacy_law:'法的要請にはどのように対応すればよいでしょうか?',
    label_privacy_law_disclosure:'法令に基づく開示',
    label_privacy_law_disclosure_data:'特定の状況下では、Shenzhen Bozee Technology Co., Ltd. は、法律に従って、または公的機関 (裁判所や政府機関など) からの有効な要求に応じて、お客様の個人データを開示する必要がある場合があります。',
    label_privacy_law_require:'法的要件',
    label_privacy_law_require_data:'Shenzhen Bozee Technology Co., Ltd は、以下の状況で必要であると信じる合理的な理由がある場合に限り、お客様の個人データを開示することがあります。',
    label_privacy_law_require_data1:'法的義務の遵守',
    label_privacy_law_require_data2:'Shenzhen Bozee Technology Co., Ltd.の権利または財産を保護および擁護します。',
    label_privacy_law_require_data3:'サービスに関連して起こり得る不正行為を防止または調査する',
    label_privacy_law_require_data4:'サービス利用者および公衆の個人の安全を保護する',
    label_privacy_law_require_data5:'法的責任の免除',


    label_privacy_safety:'データの安全性はどの程度ですか?',
    label_privacy_safety_data:'お客様のデータのセキュリティは当社にとって重要ですが、インターネット上の送信方法や電子保存方法が 100% 安全であるわけではないことにご注意ください。 当社はお客様の個人データを保護するために商業的に実行可能な手段を使用するよう努めていますが、その絶対的な安全性を保証することはできません。',


    label_privacy_delete_data:'自分に関する情報をどのように管理または削除できますか?',
    label_privacy_delete_data1:'Shenzhen Bozee Technology Co., Ltd は、お客様がご自身の個人データを修正、変更、削除したり、その使用を制限したりできるよう、あらゆる合理的な措置を講じます。',
    label_privacy_delete_data2:'個人データを変更できない場合は、必要な変更を行うために当社までご連絡ください。',
    label_privacy_delete_data3:'当社がお客様に関してどのような個人データを保持しているかを知り、当社のシステムから削除したい場合は、当社までご連絡ください。',
    label_privacy_delete_data4:'特定の状況下では、お客様には次の権利があります。',
    label_privacy_delete_data5:'あなたに関する間違った個人データを修正します',
    label_privacy_delete_data6:'個人データの削除をリクエストする',
    label_privacy_delete_data7:'あなたは、提供した情報をShenzhen Bozee Technology Co., Ltd.に転送する権利を有します。 このデータの管理と移植を容易にするために、一般的に使用される電子形式で個人データのコピーを要求できます。 かかるリクエストに応じる前に、お客様の身元を証明するようお願いする場合があることにご注意ください。',



    label_privacy_cooperate:'サードパーティパートナーとの情報の共有方法',
    label_privacy_cooperate_body:'当社は、当社のサービスを促進するため、当社に代わってサービスを提供するため、当社のサービスに関連するサービスを実行するため、または当社のサービスがどのように使用されているかの分析を支援するために、第三者の企業および個人（「サービスプロバイダー」）を雇用する場合があります。 これらの第三者は、当社に代わってこれらのタスクを実行するためにお客様の個人データにアクセスすることができ、データを開示したりその他の用途に使用したりしない義務があります。',
    label_privacy_cooperate_analyze:'分析する',
    label_privacy_cooperate_analyze_data:'当社は、当社のサービスの使用状況を監視および分析するために、サードパーティのサービスプロバイダーを使用する場合があります。',
    label_privacy_cooperate_analyze_data1:'グーグルアナリティクス',
    label_privacy_cooperate_analyze_data2:'Google Analytics は、Web サイトのトラフィックを追跡してレポートする、Google が提供する Web 分析サービスです。 Google は収集したデータを使用して、サービスの使用を追跡および監視します。 このデータは他の Google サービスと共有されます。 Google は、収集したデータを使用して、独自の広告ネットワークの広告を状況に合わせてパーソナライズする場合があります。',
    label_privacy_cooperate_analyze_data3:'通常のブラウザ環境に Google Analytics オプトアウト ブラウザ アドオンをインストールするか、アプリケーション内の設定を変更することにより、Google Analytics に提供されるサービスでのアクティビティをオプトアウトできます。 このアドオンは、Google Analytics JavaScript (ga.js、analytics.js、および dc.js) が訪問アクティビティに関する情報を Google Analytics と共有するのを防ぎます。',
    label_privacy_cooperate_analyze_data4:'Google のプライバシー慣行の詳細については、Google プライバシー規約 Web ページをご覧ください: http://www.google.com/intl/en/policies/privacy/',
    label_privacy_cooperate_firebase:'ファイアベース',
    label_privacy_cooperate_firebase_data:'Firebase は、アプリケーションの使用状況を追跡およびレポートするために Google が提供する Web 分析サービスです。 Google は収集したデータを使用して、サービスの使用を追跡および監視します。 このデータは他の Google サービスと共有されます。 Google は、収集したデータを使用して、独自の広告ネットワークの広告を状況に合わせてパーソナライズする場合があります。',
    label_privacy_cooperate_firebase_data1:'当社アプリケーション内の設定を変更することで、Google Analytics に提供されるサービスでのアクティビティを停止することを選択できます。',
    label_privacy_cooperate_firebase_data2:'Google のプライバシー慣行の詳細については、Google プライバシー規約 Web ページをご覧ください: http://www.google.com/intl/en/policies/privacy/',
    label_privacy_cooperate_advertise:'宣伝する',
    label_privacy_cooperate_advertise_data:'当社は、お客様に広告を配信し、当社サービスのサポートと維持を支援するために、サードパーティのサービスプロバイダーを使用する場合があります。',
    label_privacy_cooperate_advertise_data1:'Google AdSense DoubleClick Cookie',
    label_privacy_cooperate_advertise_data2:'Google はサードパーティ ベンダーとして、Cookie を使用して当社のサービスに広告を配信します。 Google が DoubleClick Cookie を使用することにより、Google とそのパートナーは、当社のサービスまたはインターネット上の他のサイトへのユーザーの訪問に基づいて、ユーザーに広告を配信できるようになります。',
    label_privacy_cooperate_advertise_data3:'Google 広告設定 Web ページ http://www.google.com/ads/preferences/ にアクセスして、興味・関心に基づく広告での DoubleClick Cookie の使用をオプトアウトできます。',
    label_privacy_cooperate_advertise_data4:'GoogleAdMob',
    label_privacy_cooperate_advertise_data5:'Google AdMob は Google Inc. によって提供されています。',
    label_privacy_cooperate_advertise_data6:'Google AdMob サービスをオプトアウトすることもできます。特定の操作については、Google の手順をご覧ください: https://support.google.com/ads/answer/2662922?hl=ja',
    label_privacy_cooperate_advertise_data7:'Google が収集した情報をどのように使用するかについて詳しくは、「パートナーのサイトまたはアプリを使用する際に Google がデータを使用する方法」ページをご覧ください: http://www.google.com/policies/privacy/partners / または、Google のサイトにアクセスしてください。プライバシー ポリシー: http://www.google.com/policies/privacy/',
    label_privacy_cooperate_behavior:'行動リマーケティング',
    label_privacy_cooperate_behavior_data:'お客様が当社のサービスにアクセスした後、Shenzhen Bozee Technology Co., Ltd はリマーケティング サービスを使用して、サードパーティの Web サイトに広告をプッシュします。 当社および当社のサードパーティベンダーは、Cookie を使用して、当社サービスへの過去の訪問データに基づいて広告を通知し、最適化し、配信します。',
    label_privacy_cooperate_behavior_data1:'Googleアドワーズ広告',
    label_privacy_cooperate_behavior_data2:'Google AdWords リマーケティング サービスは、Google Inc. によって提供されています。 Google 広告設定ページにアクセスして、Google アナリティクスのディスプレイ広告をオプトアウトし、Google ディスプレイ ネットワーク広告をカスタマイズできます: http://www.google.com/settings/ads',
    label_privacy_cooperate_behavior_data3:'Google では、Web ブラウザに Google Analytics オプトアウト ブラウザ アドオン (https://tools.google.com/dlpage/gaoptout) をインストールすることも推奨しています。 Google Analytics オプトアウト ブラウザ アドオンを使用すると、訪問者は Google Analytics によるデータの収集と使用を防ぐことができます。',
    label_privacy_cooperate_behavior_data4:'Google のプライバシー慣行の詳細については、Google プライバシー規約 Web ページをご覧ください: http://www.google.com/intl/en/policies/privacy/',


    label_privacy_cooperate_payment:'支払い',
    label_privacy_cooperate_payment_data:'当社は、本サービスの範囲内で有料の製品および/またはサービスを提供する場合があります。 この場合、当社はサードパーティの支払い処理サービス (支払いプロセッサなど) を使用します。',
    label_privacy_cooperate_payment_data1:'当社はお客様の支払いカード情報を保存または収集しません。 この情報は当社の第三者決済処理業者に直接提供され、第三者決済処理業者はそのプライバシー ポリシーに従ってお客様の個人情報を使用します。 これらの決済処理業者は、Visa、Mastercard、American Express、Discover などのブランドによって形成された協議会である PCI Security Standards Council のもとで PCI DSS によって設定された基準に従っています。 PCI DSS 要件は、支払い情報の安全な処理を確保するのに役立ちます。',
    label_privacy_cooperate_payment_data2:'弊社が提携している支払い処理業者は次のとおりです: PayPal',
    label_privacy_cooperate_payment_data3:'プライバシー ポリシーは https://www.paypal.com/webapps/mpp/ua/privacy-full でご覧いただけます。',

    label_privacy_cooperate_link:'他のウェブサイトへのリンク',
    label_privacy_cooperate_link_data:'当社のサービスには、当社が運営していない他のウェブサイトへのリンクが含まれる場合があります。 サードパーティのリンクをクリックすると、サードパーティの Web サイトにアクセスします。 アクセスする各 Web サイトのプライバシー ポリシーを確認することを強くお勧めします。 当社は、第三者のサイトやサービスのコンテンツ、プライバシー ポリシー、慣行を管理することはできず、それらに対して責任を負いません。',

    label_privacy_cooperate_child:'子供のプライバシー',
    label_privacy_cooperate_child_data:'当社は 13 歳未満のお子様 (以下「お子様」) にはサービスを提供しません。 当社は、13 歳未満の子供から個人を特定できる情報を意図的に収集することはありません。 あなたが親または保護者で、子供が当社に個人データを提供したことに気づいている場合は、当社までご連絡ください。 保護者の同意なしにお子様の個人データを収集したことが判明した場合、当社はこの情報を当社のサーバーから削除する措置を講じます。',

    label_privacy_cooperate_policy:'このポリシーの変更についてどのように通知しますか?',
    label_privacy_cooperate_policy_data:'当社はプライバシー ポリシーを随時更新することがあります。 プライバシー ポリシーの変更については、このページに新しいプライバシー ポリシーを掲載することでお知らせします。 当社は、変更が発効する前に、そのような変更を電子メールでお客様に通知するか、サービス上に目立つように通知を掲載し、プライバシー ポリシーの上部にある「発効日」を更新します。 このプライバシー ポリシーを定期的にチェックして、変更がないか確認することをお勧めします。 このプライバシー ポリシーへの変更は、このページに掲載された時点から有効になります。',

    label_privacy_cooperate_connect:'連絡方法は？',
    label_privacy_cooperate_connect_data:'このプライバシー ポリシーについてご質問がある場合は、以下までお問い合わせください。',
    label_privacy_cooperate_connect_data1:'電子メール: vs.l@bozee.cn',
    label_privacy_cooperate_connect_data2:'弊社 Web サイトのこのページにアクセスしてください: http://www.quicksharevip.com/privacy',

}