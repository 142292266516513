export const mobileRoutes = [
    {
        path: "/",
        name: "MobileDowload",
        // component: () => import("../../views/MobileDowload.vue"),//公共下载地址
        component: () => import("../../views/MobileAndroid.vue"),//Androd下载地址
        // component: () => import("../../views/MobileReduceDowload.vue"),//精简版下载地址
        // component: () => import("../../views/KoreaMobileDowload.vue"),//韩国客户下载地址
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import("../../views/MobilePrivacy.vue"),// 隐私说明页面
    },
]